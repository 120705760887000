.table__dropdown__toggle {
    font-size: calc(12rem / 16);
    line-height: calc(18/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem / 16);
    }
}
.table__dropdown__toggle:after {
    content: var(--icon-arrow-right);
    font-family: iconfont;
    display: inline-block;
    border: 0;
    color: #fff;
    transform: rotate(90deg);
    vertical-align: -0.1em;
    font-size: calc(12rem / 16);
    margin-left: calc(7rem / 16);
}
.table__dropdown__menu {
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    border-radius: calc(10rem/16);
}
.table__dropdown__menu .dropdown-item:hover {
    background: transparent;
    color: var(--color-primary);
}

.table,
.wysiwyg table{
    box-shadow: none;
    border-spacing: 0 10px;
    border-collapse: separate;
    width: 100%;
}

.table td,
.table th,
.wysiwyg table td,
.wysiwyg table th{
    border: none;
}

.table thead,
.wysiwyg table thead{
    border-radius: calc(1000rem/16);
    /*background: linear-gradient(228.76deg, #D2A96C 0%, var(--color-primary) 100%);*/
    color: #fff;
}

.table thead th{
    border: none;
}

.table tbody tr,
.wysiwyg table tbody tr{
    /*background-color: #fff;*/
    font-family: var(--font-default);
    border-radius: calc(10rem/16);
    transition: all 150ms ease;
}
/*
.table.table--facilities tbody tr>td:last-child{
    @media screen and (max-width: 767px){
        box-shadow: 0 24px 30px 0 rgba(51,51,51,0.1);
    }
}*/
.wysiwyg table tbody tr,
.table tbody tr{
    box-shadow: 0 10px 10px 0 rgb(51 51 51 / 5%);
}

/*-----------für abgerundete ecken muss die color am selben element wie die rundung leigen------------*/
.table thead th, .wysiwyg table thead th {
    background: linear-gradient(228.76deg,#d2a96c,var(--color-primary)) fixed;
    position: relative;
    /*border-right: calc(0.5rem/16) solid #fff;
    border-left: calc(0.5rem/16) solid #fff;*/
}
.table thead th:after, .wysiwyg table thead th:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: linear-gradient(190deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 100%);
    top: 0;
    right: 0;
}
.table thead th:last-of-type:after, .wysiwyg table thead th:last-of-type:after {
    background: transparent;
}
.table tbody tr>td, .wysiwyg table tbody tr>td {
    background-color: white;
    text-align: center;
}


/*------------------------*/

.table tbody tr>td:first-child,
.wysiwyg table tbody tr>td:first-child{
    text-align: left;
    border-top-left-radius: 10px;
    @media screen and (min-width: 768px) {
        border-bottom-left-radius: 10px;
    }
    @media screen and (max-width: 767px) {
        border-top-right-radius: 10px;
    }
}

.table tbody tr>td:last-child,
.wysiwyg table tbody tr>td:last-child{
    border-bottom-right-radius: 10px;
    @media screen and (min-width: 768px)  {
        border-top-right-radius: 10px;
    }
    @media screen and (max-width: 767px) {
        border-bottom-left-radius: 10px;
    }
}


.table thead tr>th:first-child,
.wysiwyg table thead tr>th:first-child{
    border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;

    @media screen and (min-width: 768px){
       padding-left: calc(40rem/16);
        border-top-left-radius: 1000px;
        border-bottom-left-radius: 1000px;
    }
}

.table thead tr>th:last-child,
.wysiwyg table thead tr>th:last-child{
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;

    @media screen and (min-width: 768px){
       padding-right: calc(40rem/16);
        border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;
    }
}

.table td,
.table th,
.wysiwyg table td,
.wysiwyg table th{
    vertical-align: middle;
    padding: calc(10rem/16) calc(10rem/16);
    line-height: 1.4;

    @media screen and (min-width: 1200px){
       padding: calc(12rem/16) calc(19rem/16); /*19 30*/
        font-size: calc(17rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(12rem/16) .5625rem; /*15 15*/
        font-size: calc(16rem/16);
    }

    @media screen and (max-width: 767px){
       font-size: calc(13rem/16);
    }
}

.table td,
.wysiwyg table td{
    font-family: var(--font-default);

    @media screen and (min-width: 1200px) {
        font-size: calc(16rem/16);
    }
    @media screen and (min-width: 768px) {
        font-size: calc(15rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
    }
}

/* table ticket */

.table.table--ticket{
    background: transparent;
    
    @media screen and (max-width: 767px){
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.table.table--ticket thead{
    background: transparent;
    color: var(--color-default);
    @media screen and (min-width: 768px){
       display: table-row-group;
    }
}

.table.table--ticket th{
    padding: 0 calc(15rem/16);
    background: transparent;
}

.table--ticket tbody td{
    @media screen and (max-width: 767px){
       display: block;
    }
}

.table--ticket thead{
    @media screen and (max-width: 767px){
       display: none;
    }
}


.table--facilities thead th{
    @media screen and (max-width: 767px){
       vertical-align: middle;
        text-align: center;
    }
}

.table--facilities tbody td{
    padding: calc(3rem/16) calc(10rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(3rem/16) calc(19rem/16); /*19 30*/
    }
    @media screen and (min-width: 768px){
        padding: calc(3rem/16) .5625rem; /*15 15*/
    }
}


.table.table--facilities tbody tr>td:first-child{
    @media screen and (max-width: 767px){
       border-top-left-radius: 10px;
       border-bottom-left-radius: 10px;
       border-top-right-radius: 0;
    }
}

.table.table--facilities tbody tr>td:last-child{
    @media screen and (max-width: 767px){
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
}

.table--facilities .circle-icon--facilities{
    @media screen and (max-width: 767px){
        position: absolute;
        left: -20px;
        top: 50%;
        width: 32px;
        height: 32px;
        z-index: 1;
        background: #fff;
        transform: translateY(-50%);
    }
}

.table--facilities__name{
    @media screen and (max-width: 767px){
       font-family: var(--font-default-bold);
    }
}

.table--facilities thead .bahn{
    width: 33%;
    @media screen and (min-width: 768px){
        width: 25%;
    }
}

.table--facilities thead .status{
    width: 33%;

    @media screen and (min-width: 768px){
       width: 15%;
    }
}

.table--facilities thead .schwierigkeitsgrad{
    @media screen and (min-width: 768px){
        width: 20%;
    }
}

.table--facilities thead .typ{
    @media screen and (min-width: 768px){
        width: 20%;
    }
}

.table--facilities thead .karte{
    width: 33%;

    @media screen and (min-width: 768px){
        width: 20%;
    }
}

.table--facilities thead .betriebszeiten{
    @media screen and (min-width: 768px){
        width: 20%;
    }
}
/* facilities
 */

/*
@media (hover : hover) {
    .table.table--facilities tbody tr:hover{
        box-shadow: 0 10px 30px 0 rgba(51,51,51,0.3);
        !*background-color: #F9F6F2;*!
        transform: translateX(15px);
    }

    .table tbody tr:hover td {
        background-color: #F9F6F2;
    }
}

*/

@media screen and (max-width: 767px) {
    .no-more-tables table,
    .no-more-tables thead,
    .no-more-tables tbody,
    .no-more-tables th,
    .no-more-tables td,
    .no-more-tables tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .no-more-tables table tr {
        border: 1px solid #ccc;
        border-radius: 0;
        margin-bottom: 20px;
    }

    .no-more-tables table td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        white-space: normal;
        text-align:left;
        width: 100% !important;
        display: flex;
        justify-content: flex-start;
        column-gap: 6px;
    }

    .no-more-tables__title {
        line-height: 1.2;
        align-self: center;
        width: 50%;
        text-align: left;
        flex-shrink: 0;
    }

    .no-more-tables td:first-child {
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
        border-bottom-left-radius: 0 !important;
        background-color: #e7cda0;
        justify-content: flex-start;
        column-gap: 6px;
        align-items: center;
    }

    .no-more-tables--no-title td:first-child {
        background-color: white;
        justify-content: flex-start;
    }

    .no-more-tables td:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
    }

    .no-more-tables--flex-col {
        flex-direction: column;
        & .no-more-tables__title {
            display: none;
        }
    }
}

@media screen and (min-width: 768px) {
    .no-more-tables__title {
        display: none;
    }
}