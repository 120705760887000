.navbar-main{
    position: fixed;
    z-index: 300;
    width: 100%;
    pointer-events: none;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: calc(20rem/16);
    @media screen and (max-width: 767px) {
        align-items: flex-start;
    }
    @media screen and (min-width: 768px) {
        padding: 0;
    }
}

.navbar-main-collapse,
.navbar-main-right {
    pointer-events: none;
}


.navbar-main-collapse>*,
.navbar-main-right>* {
    pointer-events: auto;
}

.navbar-main-right {
    grid-column: 3 / 4;
    @media screen and (min-width: 768px) {
        padding-right: calc(30rem/16);
    }
    & .btn {
        @media screen and (min-width: 768px) and (max-width: 1199px) {
            font-size: calc(12rem/16);
        }
    }
}

.navbar-brand{
    margin-right: 0;
    display: flex;
    justify-content: center;
    z-index: 10;
    grid-column: 2 / 3;
    pointer-events: auto;
    @media screen and (min-width: 768px){

    }
}

.navbar-brand__img{
    height: calc(120rem/16);
    width: auto;
    transition: height .3s ease;
    aspect-ratio: 1 / 1;
    @media screen and (min-width: 1400px){
        height: calc(180rem/16);
        transform: translateY(calc(30rem/16));
    }
    &.is-scrolled {
        height: calc(100rem/16);
        @media screen and (min-width: 1400px) {
            height: calc(120rem/16);
        }
    }
}

.navbar-main-nav-wrapper{
    background-color: #fff;
    padding: calc(25rem/16) 0;

    z-index: 200;

    position: fixed;
    left: 0;
    top: 0;

    @media screen and (min-width: 768px){
        position: absolute;
        left: calc(90rem/16);
        top: calc(36rem/16);
        width: calc(320rem/16);
        display: none;
        background: linear-gradient(270deg, #FFFFFF 0%, #F6F6F6 100%);
    }

    @media screen and (max-width: 767px){
        padding-top: calc(80rem/16);
        right: 0;
        bottom: 0;
        transform: translateX(-100%);
        transition: transform 300ms ease;
        display: flex;
        flex-direction: column;
        padding-bottom: calc(80rem/16);
    }
}

.navbar-main-collapse.is-open .navbar-main-nav-wrapper{
    @media screen and (min-width: 768px){
        display: block;
    }

    @media screen and (max-width: 767px){
        transform: translateX(0);
    }
}

.navbar-main-collapse{
    grid-column: 1 / 2;
    @media screen and (max-width: 767px){
        display: flex;
        position: relative;
        flex-basis: auto;
        flex-direction: column;
        align-items: flex-start;
        row-gap: calc(20rem/16);
    }
}

.navbar-main .btn-search{
    @media screen and (max-width: 767px){

    }
}

.navbar-main-nav{
    display: block;
}

.navbar-main-item{
    padding: calc(8rem/16) calc(30rem/16);
    cursor: pointer;
}


.is-active>.navbar-main-item{
    opacity: 0.4;
}

.is-active>.navbar-main-item.is-open{
    opacity: 1;
}

.navbar-main-link{
    font-family: var(--font-default-bold);
    font-size: calc(19rem/16);
    padding: calc(5rem/16) 0;

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        padding: 0;
    }
}

.navbar-main-item.active .navbar-main-link{
    color: var(--color-primary);
}


.navbar-main-right__item+.navbar-main-right__item, .navbar-main-left__item{
    margin-left: calc(10rem/16);
}
.navbar-main .btn-ticket {
    border: none;
}
/*
.navbar-main .btn-ticket .icon{
    font-size: calc(20rem/16);
    vertical-align: calc(-2rem/16);
    margin-right: calc(12rem/16);
}
*/

.navbar-main__menu{
    position: relative;
    z-index: 1000;
    pointer-events: none;
    @media screen and (min-width: 768px){
        width: calc(220rem/16);
        margin-right: calc(20rem/16);
    }

}

.navbar-main__menu-icon{
    display: none;
    @media screen and (min-width: 768px) {
        display: block;
        position: absolute;
        z-index: 50;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        filter: drop-shadow(0 5px 8px rgb(0 0 0 / 12%));
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        left: auto;
        right: 78%;
    }
}
.navbar-main__menu-iconText {
    display: none;
    @media screen and (min-width: 768px) {
        display: block;
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
        right: calc(40rem/16);
        color: var(--color-primary);
        font-size: calc(14rem/16);
        text-transform: uppercase;
        font-family: var(--font-default-bold);
    }
}
.navbar-main__menu-icon img {
    aspect-ratio: 218 / 50;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        max-width: 210px;
    }
}

.navbar-main-toggler{
    width: calc(50rem/16);
    height: calc(50rem/16);
    border-radius: 50%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    transition: 0.25s ease-in-out;

    background: linear-gradient(270deg, #D2A96D 45%, var(--color-primary) 85%);
    background-size: 150% auto;
    
    margin-left: calc(18rem/16);
    position: relative;

    @media screen and (min-width: 768px){
       width: calc(72rem/16);
       height: calc(72rem/16);
       margin-left: calc(40rem/16);
    }
    @media screen and (max-width: 767px){
       padding: 0;
        margin-left: 0;
    }
}

.navbar-main-toggler:hover {
    box-shadow: 0 calc(10rem/16) calc(10rem/16) 0 rgb(0 0 0 / 10%);
    background-position: 30%;
}

.navbar-main-toggler:focus{
    outline: none;
    box-shadow: 0 0 14px rgb(255 255 255 / 90%);
}

.navbar-main-toggler:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    width: calc(70rem/16);
    height: calc(70rem/16);
    background-color: #fff;
    opacity: 0.5;
    border-radius: 50%;
    z-index: -1;

    @media screen and (min-width: 768px){
        width: calc(98rem/16);
        height: calc(98rem/16);
    }
}

.navbar-main-toggler__bar {
    display: block;
    width: calc(27rem/16);
    height: calc(3rem/16);
    background: #fff;
    transition: transform .25s ease-in-out,opacity .25s ease-in-out;
    border-radius: 2000px;
    margin: 0 auto;

    @media screen and (min-width: 768px){
        width: calc(29rem/16);
        height: calc(4rem/16);
    }
}

.navbar-main-toggler__bar+.navbar-main-toggler__bar {
    margin-top: calc(4rem/16);
}


.navbar-collapse.is-open .navbar-main-toggler__bar:first-child {
    transform: rotate(-42deg) translateX(calc(-5rem/16)) translateY(calc(6rem/16));

   /* @media screen and (min-width: 768px) and (max-width: 880px){
        transform: rotate(-42deg) translateX(calc(-4rem/16)) translateY(calc(6rem/16));
    }*/
}

.navbar-collapse.is-open .navbar-main-toggler__bar:nth-child(2) {
    opacity: 0;
}


.navbar-collapse.is-open .navbar-main-toggler__bar:nth-child(3) {
    transform: rotate(42deg) translateX(-4px) translateY(-5px);
    @media screen and (min-width: 768px){
        transform: rotate(42deg) translateX(calc(-5rem/16)) translateY(calc(-6rem/16));
    }
/*
    @media screen and (min-width: 768px) and (max-width: 880px){
        transform: rotate(42deg) translateX(calc(-4rem/16)) translateY(calc(-6rem/16));
    }*/
}

.navbar-collapse__lang{
    @media screen and (max-width: 767px){
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.navbar-main .btn-circle:hover.btn-lang {
    background-color: var(--color-primary);
    color: #fff;
}
.navbar-main .btn-circle:hover {
    box-shadow: 0 10px 10px 0 rgb(0 0 0 / 20%);
}

.navbar-with-banner{
    position: fixed;
    z-index: 10;
}

:root {
    --navbar-scroll-progress: 0;
}

nav .background {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:-1;
    pointer-events: none;
    transform-origin: top center;
    transform: scaleY(1.35);
    background: linear-gradient(180deg,rgba(248,246,242,.95),rgba(248,246,242,.95) 12%,rgba(248,246,242,.9) 24%,rgba(248,246,242,.82) 36%,rgba(248,246,242,.72) 48%,rgba(248,246,242,.58) 60%,rgba(248,246,242,.44) 72%,rgba(248,246,242,.24) 82%, rgba(248,246,242,0.125) 90%, rgba(248,246,242,0) 100%);
    transition: 0.5s cubic-bezier(0, 0.55, 0.45, 1) opacity;
}

@media screen and (max-width: 768px) {
    nav .background {
        transform: scale(1.55);
    }
}

nav .background.js-has-background {
    opacity: 1;
}

.navbar--padding-bottom {
    padding-bottom: calc(60rem/16);
    @media screen and (min-width: 768px) {
        padding-bottom: calc(100rem/16);
    }
}