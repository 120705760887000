.footer {
    background-color: #f8f6f2;
    text-align: center;
    overflow: visible;
    position: relative;
}

.footer-newsletter{
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    top: calc(54rem/16);


    @media screen and (max-width: 767px){
        width: 80%;
    }


    	@media screen and (min-width: 768px){
            top: calc(70rem/16);
        }
}

.footer-newsletter__img-bg{
    width: 100vw;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(400rem/16);
    z-index: -2;

}

.footer-newsletter-title{

    font-family: var(--font-default-bold);
    color: #fff;
    font-size: calc(32rem/16);


    	@media screen and (min-width: 768px){
            font-size: calc(45rem/16);
    	}
}
.footer-newsletter__text{
    font-size: calc(16rem/16);
    color: #fff;
    line-height: calc(22rem/16);
    margin-bottom: calc(20rem/16);

        @media screen and (min-width: 768px){
            font-size: calc(18rem/16);
            line-height: calc(28rem/16);
            margin-bottom: calc(30rem/16);
        }
}

.footer__body {
    color: white;
    padding: calc(40rem/16) calc(30rem/16) 0 calc(30rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(65rem/16) calc(30rem/16) 0 calc(30rem/16);

    }
}
.footer__icon {
    font-size: calc(50rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(70rem/16);
    }
}
.footer__title {
    font-family: var(--font-default-bold);
    margin-bottom: calc(12rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
    }
}
.footer__btn {
    white-space: nowrap;
    @media screen and (max-width: 767px){
        width: 100%;
    }
}

.footer__btn:hover, .footer__btn:active, .footer__btn:focus {
    color: #fff;
}

.footer__wrapper{
    @media screen and (max-width: 767px) {
        margin-top: calc(-60rem/16);
        padding-bottom: calc(30rem/16);
    }
    @media screen and (min-width: 768px) {
        margin-top: calc(-100rem/16);
    }
}

.footer__container {
    padding:  0 calc(30rem/16) 0 calc(30rem/16);
    margin-top: calc(15rem/16);
    position: relative;
    height: calc(215rem/16);
    background-repeat: no-repeat;
    background-image: url('/static/img/newsletter/mountain-dark.svg');
    color: #fff;

    @media screen and (min-width: 768px) {
        background-position: initial;
        background-size: 102%;
        height: calc(170rem/16);
        margin-top: calc(30rem/16);
    }
}
.footer__bg-bottom {
    width: 100%;
    height: 0;
}

.footer__nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(52rem/16);
    width: 100%;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        bottom: calc(35rem/16);
    }
}
.footer__nav-item {
    display: inline-block;
    padding: 0 calc(15rem/16);
    color: #fff;
    font-family: var(--font-default-bold);
    line-height: 1;

    @media screen and (min-width: 992px) {
        padding: 0 calc(25rem/16);
    }
}

.footer__nav-link:hover{
    text-decoration: none;
}

.footer__whitelabel {
    font-size: calc(300rem/16);
    color: white;
    opacity: .1;
    position: absolute;
    top: 0;

    @media screen and (min-width: 1200px) {
        font-size: calc(420rem/16);
        left: auto;
    }
}

.footer__newsletter-form{
    @media screen and (min-width: 768px){
        display: flex;
        justify-content: space-between;
    }

}

.footer-newsletter__mail .form-group{
    margin-bottom: calc(10rem/16);
}
.footer-newsletter__mail .form{
    margin-bottom: calc(10rem/16);
}

.footer-newsletter__img{
    width: 100%;
    height: calc(400rem/16);
}
.footer-newsletter__img img{
    width: 100%;
    object-fit: cover;
    height: calc(400rem/16);
}

.footer-newsletter__img::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(400rem/16);
    background: linear-gradient(317deg, rgba(0,0,0,0.1), rgba(0,0,0,0.3));
}