.whitelabel {
    position: relative;
}
.whitelabel__whitelabel {
    padding-bottom: calc(20rem/16);
    text-align: center;
    @media screen and (min-width: 768px){
        text-align: right;
    }
}
.whitelabel__whitelabel-img {
    height:calc(66rem/16);
}
.whitelabel__logo {
    position: absolute;
    top: calc(-110rem/16);
    font-size: calc(878rem/16);
    color: var(--color-primary);
    left: calc(-300rem/16);
    z-index: -1;
}
.whitelabel__logo-icon:before {
    color: var(--color-primary);
    opacity: 0.1;
}
.whitelabel__link {
    font-size: calc(14rem/16);
    font-family: var(--font-default);
    line-height: 1;
}

.whitelabel__nav-text{
    color: var(--color-primary);
}

.whitelabel__claim{
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
       font-size: calc(25rem/16);
    }
}