.circle{
    width: calc(10rem/16);
    height: calc(10rem/16);
    display: inline-block;
    border-radius: 50%;
    background-color: #000;
}

.circle--status{
    width: calc(7rem/16);
    height: calc(7rem/16);
}

.status-ok{
    background-color: var(--color-success);
}

.status-partly-closed{
    background-color: var(--color-warning);
}

.status-closed{
    background-color: var(--color-danger);
}