/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}
video {
    background: #000000;
}

/* --------------- isBgBeige Klasse, wenn Seiten weiß und nur spezielle Seiten Grau ---------------------- */

body.isBgBeige, body.isBgBeige .breadcrumb {
    background: #F8F6F2;
}

/* ---------------- beige Hintergrund - falls doch nicht gewollt, diesen Abschnitt auskommentieren oder Löschen -----------*/
body, body .breadcrumb {
    background: #F8F6F2;
}
body .img-teaser-slider-area:after {
    background: linear-gradient(0deg,#f8f6f2,hsla(0,0%,100%,0));
}
body .hero__media {
    position: relative;
}
body .hero__media:after {
    background: linear-gradient(180deg, rgba(248,246,242,0) 0%, rgba(248,246,242,0) 60%, rgba(248,246,242,1) 95%);
    position: absolute;
    width: 100%;
    height: 101%;
    content: '';
    top: 0;
}

body .img-text-slide__overlay {
    background-color: rgba(248,246,242,.55);
}

body .datepicker input[readonly] {
    background: white;
}

body .gradient-box {
    background: linear-gradient(180deg,#fff,#eae0d2);
}

body .wysiwyg-with-medium__media .lightbox-img:hover {
    background: #F8F6F2;
}
body .wysiwyg-with-medium__media .lightbox-img__icon {
    color: #F8F6F2;
}