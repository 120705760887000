.portal-img-teaser-slider-area {
    position: relative;
}
.portal-main .portal-img-teaser-slider-area.slide-bg {
    @media screen and (min-width: 768px) {
        padding-bottom: calc(540rem/16);
    }
}
/*.portal-img-teaser-slider-area:before {
    position: absolute;
    z-index: 1;
    //background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, #f8f6f2 100%);
    background: linear-gradient(180deg, #f8f6f2 0%, rgba(255,255,255,0) 100%);
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
}*/

.img-teaser-slider-area.portal-img-teaser-slider-area:after {
    background: linear-gradient(180deg, #fff 0%, rgba(255,255,255,0) 100%);
    top:0;
}

.portal-img-teaser__img-wrapper {
    position: relative;
}

.portal-img-teaser__img-wrapper img{
    transition: transform 300ms ease-in-out;
}

.portal-img-teaser:hover .portal-img-teaser__img-wrapper img {
    transform: scale(1.05);
}

.portal-img-teaser__img-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg,rgb(0 0 0 / 8%),rgba(0,0,0,.1));
    z-index: 1;
    border-radius: calc(20rem/16);
    transition: all 300ms ease-in-out;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.2);
}

.portal-img-teaser:hover .portal-img-teaser__img-wrapper:before {
    opacity: 0.7;
}
.portal-img-teaser .embed-responsive {
    border-radius: calc(20rem/16);
}

.portal-img-teaser__body {
    margin-top: calc(35rem/16);
}
.portal-img-teaser__title {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: 1.25rem;
    line-height: 1.2;
    @media screen and (min-width: 992px) {
        font-size: 2.3rem;
    }
}
.portal-img-teaser__text {
    font-size: .9375rem;
    line-height: 1.5;
    @media screen and (min-width: 992px) {
        font-size: 1.25rem;
    }
}
.portal-img-teaser__btn {
    margin-top: calc(25rem/16);
}

.portal-img-teaser-slider .slick-slider {
    display: flex!important;
    flex-direction: column;
    align-items: center;
}

.portal-img-teaser-slider .slick-slider button {
    left: unset;
    margin-left: 0;
}

.portal-img-teaser-slider .slick-slider button {
    transform: translateX(35px);
}

.portal-img-teaser-slider .slick-slider button:first-child {
    transform: translateX(-35px);
}