.hold-and-drag{
    bottom: calc(-9rem / 16);
    margin-left: calc(15rem/16);
}

.portal-img-teaser-slider .hold-and-drag {
    left: calc(266rem / 16);
}

.hold-and-drag:after{
    content: '';
    position: absolute;
    height: calc(1rem/16);
    right: calc(-5000rem/16);
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 20px;
    background-color: var(--color-primary);

}

.hold-and-drag__wrapper{
    display: flex;
    align-items: center;
}
.hold-and-drag__cursor{
    width: calc(62rem/16);
    height: calc(62rem/16);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    position: relative;
    color: var(--color-primary);

    display: flex;
    align-items: center;
    justify-content: center;
    
}

.hold-and-drag__cursor:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(44rem/16);
    height: calc(44rem/16);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
}

.hold-and-drag__text{
    font-size: calc(14rem/16);	
    letter-spacing: calc(0.35rem/16);
    color: var(--color-primary);
    margin-left: calc(15rem/16);
}