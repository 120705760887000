.media-area__media,
.media-area__media video{
    border-radius: calc(20rem/16);
}

.media-area.slide-bg{
    padding-top: 0;
    @media screen and (min-width: 768px){
       padding-top: calc(100rem/16);
       padding-bottom: calc(230rem/16);
    }
}