.snowreport-element, .snowreport-element--slider {
    height: calc(300rem/16);
    width: auto;
    background-color: white;
    border-radius: 1.25rem;
    box-shadow: 0 calc(30rem/16) calc(30rem/16) 0 rgba(0,0,0,0.08);
    text-align: center;

    @media screen and (min-width: 992px) {
        height: calc(350rem/16);
        width: auto;
    }

    @media screen and (min-width: 1200px) {
        height: calc(320rem/16);
        width: calc(322rem/16);
    }

    @media screen and (min-width: 1400px) {
        height: calc(350rem/16);
        width: calc(358rem/16);
    }
}

.snowreport-element{
    padding: calc(40rem/16) calc(20rem/16);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        padding: calc(50rem/16) calc(34rem/16) calc(50rem/16) calc(44rem/16);
    }
}

.snowreport-element__inner{
    width: 100%;
}

.snowreport-element--slider {
    position: relative;
    padding: calc(80rem/16) calc(20rem/16) calc(80rem/16);

@media screen and (min-width: 992px) {
    padding: calc(100rem/16) calc(20rem/16) calc(100rem/16);
}

@media screen and (min-width: 1200px) {
    padding: calc(109rem/16) calc(20rem/16) calc(109rem/16);
}
    @media screen and (min-width: 1400px) {
        padding: calc(109rem/16) calc(20rem/16) calc(109rem/16);
    }
}

.snowreport-element__title {
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(25rem/16);
    margin-bottom: calc(5rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem/16);
        line-height: calc(30rem/16);
        margin-bottom: calc(10rem/16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(25rem/16);
    }

}

.snowreport-element__sliderbox .snowreport-item--temperature {
    display: inline-block;
    padding: calc(5rem/16);
}
.snowreport-element--slider {
    position: relative;
    overflow: hidden;
}
.snowreport-element--slider .slick-slider {
    margin: calc(18rem/16) calc(15rem/16) 0 calc(15rem/16);
    @media screen and (min-width: 768px) and (max-width: 1199px){
        margin-top: calc(40rem/16);

    }
    @media screen and (min-width: 1200px) {
        margin-top: calc(30rem/16);
        max-height: calc(80rem/16);
    }
}
.snowreport-element--slider .slick-list {
    overflow: hidden;
    max-width: 100%;
}
.snowreport-element--slider .slick-arrow {
    background-color: transparent;
    border: none;
    color: var(--color-primary);
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
    margin-top: 0;
    height: calc(58rem/16);
    width: calc(58rem/16);
}
.snowreport-element--slider .slider__arrow__icon{
    font-size: calc(24rem/16);
}
.snowreport-element--slider .slick-arrow:active {
    opacity:1;
}
.snowreport-element--slider .slick-next, .snowreport-element--slider .slick-prev {
    position: absolute;
    font-size: 1.5rem;
}
.snowreport-element--slider .slick-next {
    right: calc(-50rem/16);
      @media screen and (min-width: 768px){
         right: calc(-50rem/16);
      }
}
.snowreport-element--slider .slick-prev {
    left: calc(10rem/16);
    z-index: 1;
}

.snowreport-info__title{
    margin-bottom: calc(8rem/16);
}