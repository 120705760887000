.status-circle{
    width: calc(15rem/16);
    height: calc(15rem/16);
    vertical-align: calc(-2rem/16);
    border-radius: 50%;
    display: inline-block;
    background-color: var(--color-default);
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.3);
    position: relative;
}

.status-circle--open{
    background-color: #6DD400;
}

.status-circle--close{
    background-color: #FC4A4A;
}

.status-circle--open-condition{
    background-color: #ffc02d;
}

.status-circle.status-circle--parking:after{
    position: absolute;
    content: 'P';
    color: #fff;
    top: calc(3rem/16);
    font-size: calc(8rem/16);
    left: calc(5rem/16);
}

.difficulty{
    width: calc(15rem/16);
    height: calc(15rem/16);
    vertical-align: calc(-2rem/16);
    margin-right: calc(2rem/16);
    border-radius: 50%;
    background-color: var(--color-dark-grey);
    display: inline-block;

    @media screen and (min-width: 768px){
        margin-right: calc(4rem/16);
    }
}

.difficulty.route{
    border-radius: 0;
    transform: rotate(45deg);
}
.easy{
    background-color: #6283c7;
}

.medium{
    background-color: #EA524E;
}

.hard{
    background-color: #000;
}

.route.hard{
    background-color: #EA524E;
    border: 2px solid #000;
}

/* Tooltip */

.tooltip--facility,
.tooltip--facility .tooltip-inner{
    max-width: calc(363rem/16);
    width: 100%;
    background-color: #fff;
    color: var(--color-default);
    opacity: 1;
    text-align: left;
    border-radius: calc(30rem/16);
    padding: 0;
}

.tooltip--facility{
    border-radius: calc(30rem/16);
    padding: 0;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.05);
}
.tooltip--facility.show{
    opacity: 1;
}

.tooltip--facility__body{
    padding: calc(25rem/16) calc(30rem/16);
}

.tooltip--facility__title{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(20rem/16);
}

.tooltip--facility__img img{
    border-top-left-radius: calc(30rem/16);
    border-top-right-radius: calc(30rem/16);
}

.tooltip--facility.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip--facility.bs-tooltip-top .arrow::before {
    border-width: 30px 25px 0;
    border-top-color: #fff;
}

.tooltip--facility.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip--facility.bs-tooltip-bottom .arrow::before {
    border-width: 0 25px 30px;
    border-bottom-color: #fff;
}

.tooltip .arrow{
    width: 50px;
}