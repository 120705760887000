@media screen and (max-width: 768px) {
    section.hero--weather .hero__media figure.hero__image.hero__image--mobile {
        height: 15rem;
    }

    section.hero--weather .hero__body div.invisible {
        display: none;
    }

    section.hero--weather .breadcrumb-mobile .breadcrumb-container {
        margin-top: 0;
    }
}

