.smartbanner-show {

}

.smartbanner-show .smartbanner {
    display: block;
}

/** Default **/
.smartbanner {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-family: var(--font-default);
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    -webkit-text-size-adjust: none;

    box-shadow: 0 0 5px rgb(0 0 0 / 35%);
    background: #ffffff;
    z-index: 999;
}

.smartbanner-container {
    margin: 0 auto;
    white-space: nowrap;
}

.smartbanner-close {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 5px;
    font-family: 'ArialRoundedMTBold', Arial;
    font-size: 20px;
    text-align: center;
    color: #888;
    text-decoration: none;
    border: 0;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
    color: #aaa;
}

.smartbanner-icon {
    display: inline-block;
    vertical-align: middle;
    width: 57px;
    height: 57px;
    margin-right: 12px;
    background-size: cover;
    border-radius: 10px;
}

.smartbanner-info {
    display: inline-block;
    vertical-align: middle;
    width: 44%;
    font-size: 11px;
    line-height: 1.2em;
    font-family: var(--font-default);
}
.smartbanner-info > span{
    display: none;
}

.smartbanner-title {
    font-size: 13px;
    line-height: 18px;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}

.smartbanner-button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 10px;
    background: var(--color-primary);
    display: inline-block;
    padding: 4px 12px;
    height: auto;
}

.smartbanner-ios .smartbanner-icon {
    background: rgb(255 255 255 / 60%);
    background-size: cover;
    box-shadow: none;
}

.smartbanner-android .smartbanner-icon {
    background-color: transparent;
    box-shadow: none;
}
