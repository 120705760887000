.hero-headline-badge {
    position: relative;
}

.hero-headline-badge__img-wrapper {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
}

.hero-headline-badge__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.hero-headline-badge__body {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;

    @media screen and (max-width: 1199px) {
        width: calc(100% - 30rem/16);
        margin: 0 calc(15rem/16);
        transform: translate(calc(-50% - 15rem/16), -50%);
    }
}

.hero-headline-badge__title {
    color: var(--color-white);

    @media screen and (min-width: 768px) {
        font-size: calc(128rem/16);
    }
}

.hero-headline-badge__body .cta-badge {
    position: absolute;
    top: 0;
    left: 70%;
    transform: translateY(-100%) rotate(-14deg);

    @media screen and (min-width: 768px) {
        left: 78%;
        transform: translateY(-90%) rotate(-14deg);
    }

    @media screen and (min-width: 1200px) {
        left: 96%;
    }
}