.newsletter-banner{
    width: 100vw;
    @media screen and (max-width: 767px){
        display: none;
    }
}
.newsletter-banner__content{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: var(--color-primary);
    text-align: center;
    font-size: calc(18rem/16);
    color: #fff;
    min-height: calc(60rem/16);
}

.newsletter-banner__text{
    padding: calc(10rem/16) calc(16rem/16)  calc(10rem/16) calc(16rem/16);
}

.newsletter-banner__close{
    position: absolute;
    top: calc(7rem/16);
    right: calc(20rem/16);
    color: #fff;
}
.newsletter-banner__close .icon-close{
    color: #fff;
}