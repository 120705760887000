.consent-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: calc(24rem / 16);
    background-color: var(--color-dark-grey);
}

.consent-iframe {
    z-index: 1!important;
}