.title-block:not(.title-block--portal){
    @media screen and (min-width: 768px){
       margin-bottom: calc(40rem/16);
    }
    
    margin-bottom: calc(30rem/16);
}

.title-block__title{
    hyphens: auto;
    font-family: var(--font-default-bold);
    position: relative;
    z-index: 9;
}

.title-block__sub-title{
    font-size: calc(25rem/16);
    letter-spacing: calc(1.4rem/16);
    font-family: var(--font-default);
}


.title-block--left .title-block__title{
    @media screen and (min-width: 768px){
       font-size: calc(35rem/16);
    }
}

.title-block--main {
    margin-top: calc(120rem/16);
    margin-bottom: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(150rem/16);
        margin-bottom: calc(120rem / 16);
    }
}