.snowreport-item, .snowreport-item--temperature {
    text-align: center;
}

.snowreport-item--temperature+.snowreport-item--temperature {
    margin-left: 15px;
}

.snowreport-item__icon, .snowreport-item--temperature__icon {
    font-size: 1.6rem;
}

.snowreport-item__label, .snowreport-item--temperature__text {
    line-height: calc(35rem/16);
    font-family: var(--font-default);
}

.snowreport-item__label {
    font-size: calc(14rem/16);
    line-height: calc(18rem/16);
    font-family: var(--font-default);
    padding-bottom: calc(4rem/16);
}

.snowreport-item--temperature__degree {
    margin-left: calc(7rem/16);
}

.snowreport-item__text, .snowreport-item--temperature__degree {
    font-size: calc(16rem/16);
    line-height:1.1;
    padding-top: 10px;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        padding-top: 0;
        font-size: calc(20rem/16);
    }
}
.snowreport-item--temperature__text {
    font-size: calc(14rem/16);
    line-height: calc(16rem/16);
    width: calc(70rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
    }

    @media screen and (min-width: 1200px){
       font-size: calc(13rem/16);
    }
    
    @media screen and (min-width: 1500px){
       font-size: calc(15rem/16);
    }

}

.snowreport-item--temperature__icon {
    position: relative;
}
/*.snowreport-item--temperature__icon:before, .snowreport-item--temperature__degree {
    display: inline-block;
    position: absolute;
}
.snowreport-item--temperature__icon:before {
    right: 4px;
    bottom: calc(-7rem/16);
}*/
.snowreport-element__moreItems{
    padding: calc(14rem/16) 0;
}


.snowreport-element__oneItem .icon {
    padding-top: calc(15rem/16);

    @media screen and (min-width: 768px) {
        padding-top: 0;
        padding-bottom: calc(14rem/16);
    }
}
.snowreport-element__oneItem .snowreport-item__img {
    height: calc(45rem/16);
    @media screen and (min-width: 768px) {
        height: calc(61rem/16);
        margin: calc(7rem/16) 0 calc(15rem/16) 0;
    }
}
.snowreport-item__info{
    color: var(--color-primary);
    font-size: calc(14rem/16);
    line-height: calc(18rem/16);
    padding-top: calc(11rem/16);
}
.icon--has_text{
    font-size: calc(26rem/16);
}
.icon--no_text{
    font-size: calc(42rem/16);
}
.snowreport-item__no_text{
    margin-top: calc(13rem/16);
}