.img-content-teaser{
    position: relative;
    font-size: calc(14rem/16);
    line-height: calc(24/14);
    border-radius: calc(20rem/16);
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.08);
    background-color: #fff;
    overflow: hidden;
    height: 100%;
}
.img-content-teaser__img {
    transition: transform 300ms ease-in-out;
    transform-origin: left center;
}
.img-content-teaser:hover .img-content-teaser__img {
    transform: scale(1.05);
}
.img-content-teaser__badge {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom-left-radius: calc(20rem / 16);
    border-top-right-radius: calc(20rem / 16);
    padding: calc(4rem / 16) calc(10rem / 16);
}
.img-content-teaser__body{
    padding: calc(20rem / 16);
}


.img-content-teaser__title{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(20rem/16);
}
.img-content-teaser__body__icon {
    font-size: calc(18rem / 16);
}