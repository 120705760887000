.qparks-app-content .qparks-app-section {
    float: none !important;
    margin: 0 auto !important;
    width: 100% !important;
    border-radius: 1.25rem;
}
.qparks-app-head .qparks-app-section.qparks-app-section {
    padding: 0;
    margin-bottom: 2rem;
}
.qparks-app-text-min:before {
    background: linear-gradient(to bottom, rgba(248,246,242,0) 0%,rgba(248,246,242,1) 100%);
}
.qparks-content-section.qparks-content-section {
    border-bottom: 1px solid var(--color-primary);
    color: var(--color-text-default);
}
.qparks-latest-pic-img.qparks-latest-pic-img, .qparks-panorama-btn.qparks-panorama-btn, .qparks-text-btn.qparks-text-btn {
    border: 1px solid var(--color-primary);
}
.qparks-latest-pic-btn.qparks-latest-pic-btn {
    background-color: var(--color-primary);
}
.qparks-section-title.qparks-section-title, .qparks-text-btn.qparks-text-btn {
    color: var(--color-primary);
}


/* icons */
.pswp__button:before,
.fa {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
.pswp__button--close:before {
    content:var(--icon-close);
}
.pswp__button--arrow--right:before {
    content:var(--icon-arrow-right);
}
.pswp__button--arrow--left:before {
    content:var(--icon-arrow-left);
}
.fa-search-plus:before {
    content:var(--icon-search);
}
.fa-search-plus:before {
    content:var(--icon-search);
}
.fa-chevron-down:before {
    content:var(--icon-dropdown);
}
.qparks-text-btn.active {
    transform: rotate(180deg);
}
.qparks-text-btn.active .fa:before {
    content:var(--icon-dropdown);
}