.custom-checkbox {
    position: relative;
    padding-left: 1.7em;
    width: 100%;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: calc(18rem/16);
    height: calc(18rem/16);
    margin-top: 0.12em;
    border-radius: calc(4rem/16);
}
.custom-checkbox__box:before {
    content: "";
    position: absolute;
    width: calc(10rem/16);
    height: calc(10rem/16);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-size: calc(15rem/16);
    margin-top: calc(2rem/16);
}
.custom-checkbox__text a {
    border: none;
    position: relative;
    box-shadow: none;
    overflow: hidden;
    padding: calc(3rem/16) 0;
    color: var(--color-primary);
    text-decoration: none;
    cursor: pointer;
}

/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
    margin-right: 0;
}
