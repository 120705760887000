.img-teaser-grid{
    padding-bottom: calc(40rem/16);

    @media screen and (min-width: 768px){
        padding-bottom: calc(60rem/16);
    }
}

.img-teaser-grid.img-teaser-grid--in-hero{
    margin-top: -80%;
    padding-bottom: 0;
    margin-bottom: calc(40rem/16);

    @media screen and (min-width: 768px){
        margin-top: -20%;
        margin-bottom: calc(50rem/16);
    }
}

.img-teaser-grid .btn-load-more{
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-size: calc(25rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(60rem/16);
    }
}