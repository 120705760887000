.cta-badge {
    background-color: var(--color-primary);
    border-radius: 50%;
    padding: calc(8rem/16);
    color: var(--color-white);
    font-family: var(--font-default-bold);
    height: calc(120rem/16);
    width: calc(120rem/16);
    box-shadow: 0 0 0 var(--color-primary);
    transition: box-shadow 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        font-size: calc(26rem/16);
        line-height: calc(36/26);
        height: calc(200rem/16);
        width: calc(200rem/16);
        padding: calc(14rem/16);
    }

    &:focus,
    &:hover {
        color: var(--color-white);
        box-shadow: 0 0 calc(40rem/16) var(--color-primary);
    }
}

.cta-badge__text {
    border: var(--color-white) solid calc(2rem/16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: calc(8rem/16);
    text-align: center;

    @media screen and (min-width: 768px) {
        padding: calc(14rem/16);
    }
}