.lightbox-video{
    position: relative;
    cursor: pointer;
}
.lightbox-video .btn-play{
    position: absolute;
    width: calc(70rem/16);
    height: calc(70rem/16);
    border-radius: 50%;
    color: var(--color-primary);
    font-size: calc(25rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (min-width: 768px){
        width: calc(100rem/16);
        height: calc(100rem/16);
        font-size: calc(30rem/16);
    }
}


.lightbox-video.lightbox-video--thumb .btn-play{
    width: calc(25rem/16);
    height: calc(25rem/16);
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        width: calc(50rem/16);
        height: calc(50rem/16);
        font-size: calc(22rem/16)
    }
}


.btn.btn-lightbox{
    position: absolute;
    right: calc(-15rem/16);
    bottom: calc(-15rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 2px solid #fff;
    border-radius: 50%;
    content: '';
    opacity: 0;
    transform: scale3d(0.65,0.65,1);
    transform-origin: bottom right;
    transition: opacity 0.35s, transform 0.35s;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #fff;
    z-index: 5;
}

.btn-lightbox .icon{
    font-size: calc(18rem/16);
    position: relative;
    top: calc(-3rem/16);
    left: calc(-3rem/16);
}

.lightbox-img:hover .btn-lightbox ,
.lightbox-video:hover .btn-lightbox {
    @media (hover: hover) {
        opacity: 1;
        transform: scale3d(1,1,1);
    }

}

.lightbox-img{
    position: relative;
}

.lightbox-img:after{
    @media (hover: hover) {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255,255,255,0.3);
        opacity: 0;
        pointer-events: none;
        transition: all 250ms ease;
    }

}

.lightbox-img:hover:after{
    opacity: 1;
}

.lightbox-img__icon{
    color: #fff;
    font-size: calc(180rem/16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: all 250ms ease;
    @media (hover: none) {
        display: none;
    }
}

.lightbox-img:hover .lightbox-img__icon{
    opacity: 0.65;
}