.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 1.2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.bg-isgold .wysiwyg a{
    color: #fff;
    text-decoration: underline;
}

.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg ul {
    list-style: none;
    padding-left: 0;
}
/*--------------*/
.wysiwyg ul ul {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(12rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-top: calc(16rem / 16);
    }
}

.wysiwyg ul li {
    position: relative;
    padding-left: calc(22rem / 16);
}
.wysiwyg ul li:before {
    content: '';
    position: absolute;
    width: calc(6rem / 16);
    height: calc(6rem / 16);
    top: calc(12rem / 16);
    left: 0;
    background: var(--color-primary);
    border-radius: 100%; /*---------------*/

    @media screen and (min-width: 768px) {
        width: calc(9rem / 16);
        height: calc(9rem / 16);
    }
}

.text-center .wysiwyg ul li:before {
    transform: translateX(-20px);
    left: auto;
    right: auto;
}
.wysiwyg ul li + li {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(12rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-top: calc(16rem / 16);
    }
}

/*---------------*/
.bg-isgold {
    background: var(--color-primary);
    color: white;
}
.bg-isgold table {
    color: black;
}


.wysiwyg-with-medium__app-links {
    margin-top: 1.5rem;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.wysiwyg.wysiwyg--text-expand{
    position: relative;
    @media screen and (max-width: 767px){
        height: calc(180rem/16);
        overflow: hidden;
    }
}

.wysiwyg.wysiwyg--text-expand:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(248,246,242,1) 0%, rgba(248,246,242,0) 40%);
    pointer-events: none;
}


.wysiwyg.wysiwyg--text-expand.show-content{
    height: auto;
}

.wysiwyg .wysiwyg-readmore-btn{
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-size: calc(70rem/16);
}

