/*----------------- social media teaser -----------------*/

.social-media-teaser{
    position: relative;
    font-size: calc(14rem/16);
    line-height: calc(24/14);
    border-radius: calc(20rem/16);
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.08);
    background-color: #fff;
    overflow: hidden;
    height: 100%;
}
.social-media-teaser__img {
    transition: transform 300ms ease-in-out;
    transform-origin: left center;
}
.social-media-teaser:hover .social-media-teaser__img {
    transform: scale(1.05);
}
.social-media-teaser__body{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: calc(12rem / 16);
    transition: opacity 300ms ease-in-out, background-color 300ms ease-in-out;

    	@media screen and (min-width: 768px){
            padding: calc(20rem / 16);
        }
}

.social-media-teaser:hover  .social-media-teaser__body{
    transition: opacity 300ms ease-in-out, background-color 300ms ease-in-out;
    background-color: rgba(0,0,0,0.6);
    opacity: 1;
}

.social-media-teaser__hashtags-wrapper {
    display: flex;
    justify-content: space-between;
    color: var(--color-primary);
    align-items: center;
}

.social-media-teaser__hashtags {

    @media screen and (max-width: 1820px) and (min-width: 992px){
        line-height: calc(12rem/16);
        font-size: calc(10rem/16);
    }

    @media screen and (min-width: 768px) {
        font-size: calc(12rem/16);
    }

    @media screen and (max-width: 767px){
        line-height: calc(10rem/16);
        font-size: clamp(calc(8rem/16), 2.7vw, calc(10rem/16));
    }
}
.social-media-teaser__icon {
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.social-media-teaser__description {
    color: #fff;
    @media screen and (max-width: 1820px) and (min-width: 992px){
        line-height: calc(16rem/16);
        font-size: calc(12rem/16);
    }
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        height: 100%;
        overflow: hidden;
    }

    @media screen and (max-width: 546px){
        line-height: clamp(calc(10rem/16), 3.2vw, calc(12rem/16));
        font-size: clamp(calc(10rem/16), 2.7vw, calc(12rem/16));
    }
}

/*---------- social-media-wall ------------*/
.social-media-teaser-area__title {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(45rem/16);
        margin-bottom: 0;
    }
}
.social-media-teaser-area__subtitle {
    font-family: var(--font-default);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(25rem/16);
    }
}

.social-media-teaser-area__teaser-area {
    @media screen and (min-width: 768px) {
        margin-top: calc(49rem/16);
        margin-bottom: calc(55rem/16);
    }
}

.social-media-teaser-area__btn-wrapper {
    position: relative;
    text-align: center;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        margin-top: calc(40rem/16);
    }
}

.social-media-teaser-area__btn:before {
    width: 5000px;
    transform: translateX(-100%) translateY(-50%);
    top: 50%;
    height: .0625rem;
    background-color: var(--color-primary);
    position: absolute;
    content: '';
    left: -20px;
}

.social-media-teaser-area__btn:after {
    content: '';
    height: .0625rem;
    right:0;
    position: absolute;
    transform: translateY(-50%);
    left: 220px;
    width: 50000px;
    background-color: var(--color-primary);
    top: 50%;
}

/*------------- only portal class ----------------*/
.portal-main .social-media-teaser-area {
    @media screen and (max-width: 767px) {
        padding-bottom: 3.5rem;
    }
    @media screen and (min-width: 768px) {
        margin-top: calc(-478rem/16);
    }
}