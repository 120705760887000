.form-group {
    position: relative;
}

.datepicker input[readonly]{
    background: transparent;
}
.datepicker:after {
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    right: calc(20rem / 16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);
    font-size: calc(16rem / 16);
    pointer-events: none;
}
.ui-datepicker {
    z-index: 300!important;
    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
        width: calc(100% - 30px);
    }
}