@media screen and (max-width: 767px){
   .mt-xs-30{
       margin-top: calc(30rem/16);
   }
}

.mt30{
    margin-top: calc(30rem/16);
}

.hidden {
    display: none;
}

#Coi-Renew {
@media screen and (max-width: 767px) {
    bottom: calc(56.8rem/16) !important;
}
}

/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-underline {text-decoration: underline}


/* Text Color Helper */
.text-primary-hover:hover { color: var(--color-primary); }
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }

/* bg */
.bg-primary {
    background-color: var(--color-primary);
}

/* ----- Text gradients ----- */

/* Für alle Browser die das supporten */
@supports ( -webkit-background-clip: text) {
    .text-primary-gradient{
        background: linear-gradient(227.99deg, var(--color-primary) 0%, var(--color-primary-gradient-contrast) 30.2%, var(--color-primary) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .text-primary-gradient-light{
        background: linear-gradient(227.99deg, var(--color-primary) 0%, #fff 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    /*-----------------------*/
    .text-primary-gradient-light--mirrored {
        background: linear-gradient(68.99deg, var(--color-primary-gradient-contrast) 0%, #fff 40%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

/* Fallback für IE z.b. */
.text-primary-gradient-light,
.text-primary-gradient{
    display: inline-block;
    color: var(--color-primary);
}

/* --------------------- */

/* Background Color Helper */



/* Additional */

.w-100-xs{
    @media screen and (max-width: 767px){
       width: 100%;
    }
}

.btn-no-styling{
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.js-go-to-link{
    cursor: pointer;
}


.link-arrow {
    font-size: calc(10rem / 16);
    margin-right: calc(2rem / 16);
    transition: transform 200ms ease-in-out;
}
a:hover > .link-arrow {
    transform: translateX(calc(3rem / 16));
}

/*-------------klickverhalten-----------------*/
.pointerEvent-none {
    pointer-events: none;
}

/*---------------shadow--------------------*/
.text-shadow-white {
    text-shadow: 0px 1px 15px #fff;
}


.map-lightbox iframe,
.iframe-border-radius {
    border-radius: 1.25rem;
}

.coi-banner__heading img {
    aspect-ratio: 500 / 152;
}