.download-item {
    padding: 0 calc(10rem / 16);
    transition: all .15s ease;
}

@media (hover: hover) {
    .download-item:hover {
        box-shadow: 0 calc(10rem/16) calc(30rem/16) 0 rgb(51 51 51 / 20%);
        background-color: #fff;
        transform: translateX(15px);
        border-radius: 0.625rem;
    }
}

.download-item__title {
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(25rem / 16);
    }
}
.download-item__icon {
    font-size: calc(20rem / 16);
    vertical-align: -.2em;

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}
.download-item__meta li {
    position: relative;
}
.download-item__meta li + li {
    margin-left: calc(8rem / 16);
    padding-left: calc(8rem / 16);
}
.download-item__meta li + li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: calc(1rem / 16);
    background: currentColor;
}