.warning--disrupter {
    position: fixed;
    bottom: 20%;
    z-index: 200;
    right: 0;
    transform: translateX(calc(100% - 55px));
    pointer-events: none;

    @media screen and (min-width: 768px) {
        right: 0;
        bottom: 33%;
        transform: translateX(calc(100% - 130px));
    }
}
.warning--disrupter.is-open {
    pointer-events: all;
}

/*------------------ btn / icon --------------*/
.warning--disrupter__btn {
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 calc(15rem/16) 0 rgba(0,0,0,0.12);
    z-index: 10;
    height: calc(50rem/16);
    width: calc(50rem/16);
    position: absolute;
    bottom: -5px;
    left: -20px;
    text-align: center;
    border: none;
    pointer-events: auto;

    @media screen and (min-width: 768px) {
        height: calc(93rem/16);
        width: calc(93rem/16);
        bottom: calc(-10rem/16);
        left: calc(-30rem/16);
    }
}
.warning--disrupter__btn:focus, .warning--disrupter__btn[type="submit"]:focus {
    outline: none;
}

.warning--disrupter__btn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(70rem/16);
    height: calc(70rem/16);
    background-color: white;
    opacity: 0.5;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 0 calc(15rem/16) 0 rgba(0,0,0,0.12);

    @media screen and (min-width: 768px) {
        width: calc(134rem/16);
        height: calc(134rem/16);
    }
}
.warning--disrupter__icon {
    color: var(--color-primary);
    position: absolute;
    font-size: 2rem;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    @media screen and (min-width: 768px) {
        font-size: calc(45rem/16);
    }
}
.is-open .warning--disrupter__icon {
    font-size: 1.5rem;

    @media screen and (min-width: 768px) {
        font-size: calc(50rem/16);
    }
}
/*------------- infobox ----------------*/

.warning--disrupter__body {
    text-align: right;
    color: white;
    padding: calc(20rem/16) calc(30rem/16) calc(35rem/16) calc(40rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) calc(45rem/16) calc(29rem/16) calc(80rem/16);
    }
}
.warning--disrupter-img {
    border-radius: calc(34.5rem / 16) 0 0 0;
}
.warning--disrupter__body {
    transform: scaleY(0);
    opacity: 0;
    transform-origin: bottom;
}
.warning--disrupter-img {
    opacity: 0;
}

.warning--disrupter__infobox {
    width: 17rem;
    border-radius: calc(34.5rem/16) 0 0 calc(10rem/16);
    background: linear-gradient(227.99deg, var(--color-primary) 0%, #D2A96C 30.2%, var(--color-primary) 100%);
    transform: scaleY(0.102);
    transform-origin: bottom;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        transform: scaleY(0.18);
        width: calc(382rem/16);
    }
}

.warning--disrupter__title {
    font-family: var(--font-default-bold);
    letter-spacing: 1.25px;
    font-size: calc(16rem/16);
    margin-bottom: calc(4rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        margin-bottom: calc(6rem/16);
    }
}
.warning--disrupter__text {
    font-family: var(--font-default);
    letter-spacing: 1px;
    line-height: calc(20rem/16);
    font-size: calc(14rem/16);
    margin-bottom: calc(16rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(19rem/16);
        line-height: calc(18rem/16);
        font-size: calc(16rem/16);
    }
}

/*-------------- opened box ------------*/
.is-open.warning--disrupter {
    transform: translateX(0);

    @media screen and (min-width: 768px) {
        transform: translateX(0);
    }
}
.is-open .warning--disrupter__infobox {
    transform: scaleY(1);
}
.is-open .warning--disrupter__body {
    transform: scaleY(1);
    opacity: 1;
}
.is-open .warning--disrupter-img {
    opacity: 1;
}

/*------------- transition class ----------------*/
.has-transition.warning--disrupter, .has-transition .warning--disrupter__infobox {
    transition: all 0.20s ease-in-out;
}
.has-transition .warning--disrupter-img {
    transition: opacity 0.20s ease-in-out;
}