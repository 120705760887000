@media screen and (min-width: 768px){
    .row--shifted-cols-md>[class^="col"]:nth-child(2),
    .row--shifted-cols-md>[class*=" col"]:nth-child(2){
        position: relative;
        top: calc(50rem/16);
    }
}

.row--border>[class^="col"]:not(:last-child),
.row--border>[class*=" col"]:not(:last-child){
    border-right: 1px solid #e2e2e2;
}
