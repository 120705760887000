.modal-header .close{
    font-size: calc(20rem/16);
    color: var(--color-primary);
    opacity: 1;
    margin: 0;
    margin-left: auto;
}

.modal-body{
    padding: calc(20rem/16);

    @media screen and (min-width: 768px){
       padding: calc(50rem/16) calc(82rem/16) calc(50rem/16);
    }
}

.modal-header{
    padding: calc(15rem/16) calc(30rem/16) 0;
    align-items: center;
}

.modal-backdrop.show{
    opacity: 0.5;
}

.modal--warning .modal-body{
    padding: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(82rem/16) calc(70rem/16);
    }
}

.modal-body.modal-body--region{
    padding: calc(25rem/16) calc(30rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) calc(100rem/16);
    }
}

/* ------------ modal-gold ---------------*/
.modal-gold .modal-content {
    background-color: var(--color-primary);
    color: white;
}
.modal-gold .modal-header button {
    color: white;
}