.embed-responsive.embed-responsive-cover{
    height: 100%;
}

.embed-responsive.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px){
    .embed-responsive.embed-responsive-cover-md{
        height: 100%;
    }

    .embed-responsive.embed-responsive-cover-md .embed-responsive-item{
        width: auto;
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.embed-responsive-box-img:before {
    padding-top: 98%;
}

.media-area__embed{
    border-radius: calc(20rem/16);
}

.embed-responsive-intermaps:before{
    @media screen and (min-width: 768px) {
        padding-top: 76.79%;
    }

    @media screen and (max-width: 767px) {
        padding-top: 77%; /*150%*/
    }
}

.embed-responsive-modal-img:before{
    padding-top: 28.1195%;
}


/*----------------- intermaps größe ------------------*/
.lg-has-iframe .lg-video {
    height: 81vh;
    padding-bottom: 0;
    @media screen and (min-width: 390px) {
        height: 86vh;
    }
    @media screen and (min-width: 768px) {
        height: 87vh;
    }
}


.embed-responsive-1by1-xs:before {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}