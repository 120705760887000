.btn-outline-primary{
    background-color: transparent;
    border-color: var(--color-primary);
    color: #000;
}

.btn-outline-primary:hover{
    color: #fff;
}

.btn.btn-primary{
    /*background: linear-gradient(227.01deg, #D2A96C 30.2%, var(--color-primary) 100%);*/
    transition: .25s ease-in-out;
    background: linear-gradient(227.01deg, #D2A96C 50.2%, var(--color-primary) 70%);
    background-size: 200% auto;
    border-color: transparent;
    position: relative;
}

.btn.btn-primary:hover {
    background-position: 30%;
}

.btn-no-style{
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: left;
}

.btn-circle{
    width: calc(52rem/16);
    height: calc(52rem/16);
    border-radius: 50%;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    padding: calc(5rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-default-bold);
    z-index: 1;
}

.btn-circle:focus{
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
}

.btn-circle .icon{
    font-size: calc(22rem/16);
}

.btn-icon-text__icon{
    vertical-align: calc(-4rem/16);
    margin-right: calc(5rem/16);
    font-size: calc(20rem/16);
}

.btn-white{
    background-color: #fff;
    color: var(--color-primary);
}

.btn-sm {
    font-size: calc(14rem / 16);
    padding: calc(10rem / 16) calc(15rem / 16);
}

.btn-lang{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-newsletter{
    color: var(--color-primary);
    background-color: #fff;
}

.btn-newsletter:hover{
    background-color: var(--color-primary);
    color: #fff;
}

.btn-lang.active{
    background-color: var(--color-primary);
    color: #fff;

}

.btn-outline-white{
    color: #fff;
    border: 1px solid #fff;
    border-radius: calc(25.5rem/16);
    padding: calc(10rem / 16) calc(23.5rem / 16);
}
.btn-outline-white:hover{
    color: var(--color-primary);
    background-color: #fff;
}

.btn.btn-expanded {
   padding: calc(12rem/16) calc(45rem/16);
}

.btn.btn-wait {
    background-color: #FA5425;
    color: white;
    animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
    0% { background-color: #FA5425; box-shadow: 0 0 3px #FA5425; }
    50% { background-color: #CB3409; box-shadow: 0 0 40px #CB3409; }
    100% { background-color: #FA5425; box-shadow: 0 0 3px #FA5425; }
}