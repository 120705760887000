.accordion{
    @media screen and (max-width: 767px){
       margin: 0 calc(-15rem/16);
    }
}
.accordion .card{
    background-color: transparent;
    border: none;
    overflow: visible;
}

.accordion .card+.card{
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
    }
}

.accordion .card-header{
    border-radius: 0;
    background: linear-gradient(228.76deg, #D2A96C 53%, var(--color-primary) 75%);
    background-size: 200% auto;
    color: #fff;
    border: none;
    transition: 0.25s ease-in-out;
    position: relative;
    z-index: 9;

    @media screen and (min-width: 768px){
        border-radius: calc(1000rem/16);
    }
}
.accordion .card-header:hover {
    background-position: 40%;
}

.accordion>.card:not(:first-of-type) .card-header:first-child{
    border-radius: 0;
    @media screen and (min-width: 768px){
        border-radius: calc(1000rem/16);
    }
}

.accordion__header-link{
    line-height: 1.5;
}

.accordion__title{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        margin-left: calc(20rem/16);
    }
}

.accordion--big .accordion__title{
    @media screen and (min-width: 768px){
       font-size: calc(24rem/16);
        margin-left: calc(30rem/16);
    }
}

.accordion__subtitle{
    font-size: calc(14rem/16);
    line-height: calc(16/14);
    display: inline-block;
    
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.accordion__toggle{
    width: calc(35rem/16);
    height: calc(35rem/16);
    border-radius: 50%;
    background-color: #fff;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(20rem/16);
}

.accordion__toggle-icon{
    position: relative;
}

.accordion__toggle-icon:before{
    content: '-';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: var(--color-primary);
    font-family: var(--font-default);
    font-size: calc(40rem/16);
    line-height: 1;
    display: block;
    top: calc(-3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(40rem/16);
    }
}

.accordion--big .accordion__toggle-icon:before{
    top: calc(-3rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(50rem/16);
    }
}

.collapsed .accordion__toggle-icon{
    @media screen and (max-width: 767px){
        top: calc(2rem/16);
    }
}

.accordion--big .collapsed .accordion__toggle-icon:before{
    content: '+';
    font-size: calc(30rem/16);
    top: calc(-3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(40rem/16);
    }
}


.collapsed .accordion__toggle-icon:before{
    content: '+';
    font-size: calc(20rem/16);
    top: calc(-2rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(30rem/16);
    }
}


.accordion--big .accordion__toggle{
    @media screen and (min-width: 768px){
        width: calc(50rem/16);
        height: calc(50rem/16);
    }
}

.hero .accordion--skiticket{
    @media screen and (min-width: 768px){
       margin-bottom: calc(180rem/16);
    }
}

.accordion--skiticket .card-body{
    padding: 0 calc(15rem/16) calc(30rem/16);
    @media screen and (min-width: 768px){
       margin-top: calc(-18rem/16);
    }
}

/*----------------------accordion--skiticket styling über table---------------*/
.accordion--skiticket .container {
    @media screen and (max-width: 767px) {
        overflow: auto;
    }
}
.accordion--skiticket table {
    background: transparent;
    position: relative;
    border-spacing: 0 calc(8rem/16); /*14px*/
    border-collapse: separate;
    box-shadow: none;
    width: 100%;
    margin-bottom: calc(14rem/16);
    margin-top: calc(8rem/16);
   /* white-space: nowrap;*/

    @media screen and (min-width: 768px) {
        border-spacing: 0 calc(10rem/16); /*20px*/
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 1rem;
    }
    
    @media screen and (max-width: 767px){
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.accordion--skiticket table thead{
    background: transparent;
    color: var(--color-default);
    @media screen and (min-width: 768px){
        display: table-row-group;
    }
}
.accordion--skiticket table thead{
    line-height: 1.4;
    @media screen and (max-width: 767px){
        /*display: none;*/
        font-size: calc(14rem/16);
    }
}

.accordion--skiticket table th{
    padding: 0 calc(15rem/16);
    background: transparent;
}

.accordion--skiticket table tbody td {
    line-height: 1.6;
    @media screen and (max-width: 767px){
        /*display: block;*/
    }
}

.accordion--skiticket table:before {
    @media screen and (min-width: 768px){
        content: var(--icon-snow);
        font-family: iconfont;
        position: absolute;
        left: 20%;
        top: calc(115rem/16);
        color: var(--color-primary);
        opacity: 0.1;
        font-size: 800px;
        z-index: -1;
        transform: translateX(-100%);
        line-height: 1;
    }
}

.accordion--skiticket table tbody tr td {
    padding: calc(11rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        width: calc(300rem/16);
        padding: calc(14rem/16);
    }
    @media screen and (min-width: 768px) {
        padding: calc(9rem/16); /* 0.9375rem*/
    }
}
.accordion--skiticket table tbody tr td p {
    margin: 0;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
}

.accordion--skiticket table tbody, .accordion--skiticket table thead {
    text-align: center;
}

.accordion--skiticket table tbody tr {
    /*background-color: #fff;*/
    box-shadow: 0 10px 30px 0 rgb(51 51 51 / 5%);
    border-radius: .625rem;
}
.accordion--skiticket table tr>td {
    background-color: white;
}

.accordion--skiticket table tr>td:first-child {
    border-top-left-radius: calc(10rem/16);
    border-bottom-left-radius: calc(10rem/16);
}
.accordion--skiticket table tr>td:last-child {
    border-top-right-radius: calc(10rem/16);
    border-bottom-right-radius: calc(10rem/16);
}

.accordion--skiticket table thead tr>th:first-child {
    @media screen and (min-width: 768px){
        padding-left: calc(40rem/16);
        border-top-left-radius: 1000px;
        border-bottom-left-radius: 1000px;
    }
}

.accordion--skiticket table thead tr>th:last-child {
    @media screen and (min-width: 768px){
        padding-right: calc(40rem/16);
        border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;
    }
}

/*.accordion--skiticket table tbody tr:hover td {
    background-color: #F9F6F2;
}*/

.accordion--skiticket tbody a, .accordion--skiticket tbody a:focus, .accordion--skiticket tbody a:hover {
    color: var(--color-primary);
}
