.anchor-nav{
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    display: block;
}
.anchor-nav__item{
    text-align: right;
    line-height: 1.1;
    position: relative;
}

.anchor-nav__item__link {
    font-family: var(--font-default-bold);
    font-weight: 400;
    line-height: 1;
    padding: 0 calc(50rem / 16) 0 0;
    position: relative;
    display: inline-block;
    transform: translateX(calc(20rem / 16));
    transition: transform 200ms ease-in-out;
}
.anchor-nav__item__link.active,
.anchor-nav__item__link:hover {
    transform: translateX(0);
}
.anchor-nav__item__link:after {
    content: '';
    height: calc(2rem / 16);
    width: calc(40rem / 16);
    background: #000;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.anchor-nav__item__link__title{
    display: inline-block;
    white-space: nowrap;
    transition: transform 250ms ease-in-out, opacity 200ms ease-in-out;
    transform: translateX(calc(20rem / 16));
    opacity: 0;

    @media screen and (min-width: 768px){
        max-width: calc(200rem/16);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.anchor-nav__item__link:hover .anchor-nav__item__link__title,
.anchor-nav__item__link.active .anchor-nav__item__link__title {
    transform: translateX(0);
    opacity: 1;
}