.console-affix__container .console {
    margin-top: unset;
}

.console.console--portal {
    @media screen and (min-width: 768px){
        position: absolute;
        bottom: unset;
        left: unset;
        right: unset;
    }
    @media screen and (max-width: 767px){
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }
}
.console {
    position: fixed;
    z-index: 10;
    @media screen and (min-width: 768px){
        position: fixed;
        bottom: calc(120rem/16);
        left: 0;
        z-index: 10;
    }
    @media screen and (max-width: 767px){
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
    }
}

.console__container {
    padding: calc(0rem/16) calc(12rem/16);

    @media screen and (min-width: 768px){
        padding: calc(0rem/16) calc(32rem/16);
    }

    @media screen and (min-width: 1820px){
        padding: calc(0rem/16) calc(42rem/16);
    }

    @media screen and (max-width: 767px){
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        background: linear-gradient(227.01deg, #D2A96C 30.2%, var(--color-primary) 100%);
        width: 100%;
    }
}

.console--portal {
    @media screen and (min-width: 768px) {
        position: absolute;
    }
}

.console__container--portal {
    @media screen and (min-width: 768px) {
        opacity: 0;
        animation: fadeOutAnimation ease-in-out 500ms;
    }
}

@keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.console__container.console__container--portal.is-affix {
    display: unset;
    z-index: 100;
    opacity: 1;
    animation: fadeInAnimation ease-in-out 500ms;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.console__item {
    width: calc(52rem/16);
    height: calc(52rem/16);
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    position: relative;

    @media screen and (max-width: 767px){
        background-color: transparent;
        box-shadow: none;
    }
    @media screen and (max-height: 600px) and (min-width: 786px) {
        width: calc(45rem/16);
        height: calc(45rem/16);
    }
}

.console__item__icon{
    color: var(--color-primary);
    font-size: calc(24rem/16);
    line-height: 1;

    @media screen and (max-width: 767px){
        color: white;
        font-size: calc(24rem/16);
    }
    @media screen and (max-height: 600px) and (min-width: 786px) {
        font-size: calc(20rem/16);
        top: 56%;
        position: absolute;
        transform: translateY(-56%);
    }
}
.console__item__icon:active {
    @media screen and (max-width: 767px) {
        color: var(--color-secondary-dark);
    }
}

.console li:not(:last-child) .console__item{
    @media screen and (min-width: 768px){
        margin-bottom: calc(50rem/16);
    }
    @media screen and (min-height: 601px) and (max-height: 900px) and (min-width: 786px) {
        margin-bottom: calc(25rem/16);
    }
    @media screen and (max-height: 600px) and (min-width: 786px) {
        margin-bottom: calc(15rem/16);
    }
}

.console__item__icon.icon-weather{
    @media screen and (min-width: 768px){
       font-size: calc(29rem/16);
    }
}

.console__item__icon.icon-ski{
    @media screen and (min-width: 768px){
        font-size: calc(28rem/16);
    }
}


.console__item__icon.icon-snow{
    @media screen and (min-width: 768px){
        font-size: calc(29rem/16);
    }
}

.console__item__icon.icon-lift{
    @media screen and (min-width: 768px){
        margin-left: calc(-3rem/16);
    }
}


.console__item__main{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(0);
    left: 0;
    opacity: 0;
    transform-origin: left center;
    transition: transform 450ms ease, opacity 450ms ease;
    z-index: 20;
}

@media (hover: hover) and (min-width: 768px) {
    .console__item:hover .console__item__main{
        transform: translateY(-50%) scale(1);
        opacity: 1;
    }
}

.console__item__img{
    width: calc(170rem/16); /*230*/
    height: calc(170rem/16);
    border-radius: 50%;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.08);
    border: calc(3rem/16) solid #fff;
    display: inline-block;
    opacity: 1;
    background-color: white;

    @media screen and (max-height: 600px) and (min-width: 786px) {
        width: calc(100rem/16);
        height: calc(100rem/16);
    }
    @media screen and (max-width: 1199px) {
        width: calc(110rem/16);
        height: calc(110rem/16);
    }
}
.console__item__img:before {
    background-color: red;
}

.console__item__img-effect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    transition: .5s ease;
    background-color: white;
    border-radius: 100%;
}

.console__item__content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

.console__item__icon-big{
    font-size: calc(45rem/16); /*45*/
    color: var(--color-primary);

    @media screen and (max-width: 1199px) {
        font-size: calc(38rem/16);
    }
    @media screen and (max-height: 600px) and (min-width: 786px) {
        font-size: calc(28rem/16);
    }
}

.console__item__icon-big.icon-weather,
.console__item__icon-big.icon-snow{
    font-size: calc(46rem/16);

    @media screen and (max-height: 600px) and (min-width: 786px) {
        font-size: calc(30rem/16);
    }
}

.console__item__title{
    font-size: calc(24rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    line-height: calc(35/30);
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        width: calc(280rem/16);
    }
    @media screen and (max-width: 1199px) {
        font-size: 1rem;
    }
    @media screen and (max-height: 600px) and (min-width: 786px) {
        font-size: calc(14rem/16);
        letter-spacing: calc(0.5rem/16);
    }
}

.console__item__values{
    letter-spacing: 0;
    white-space: nowrap;
}

.console__item__small{
    font-size: calc(15rem/16);
    text-transform: none;
}

.console__container {
    /* add some extra space because of labels */
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
}

.console__item {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
}

.console__item .console__item__label {
    font-size: 0.7rem;
    line-height: 1;
    color: white;
}

@media screen and (min-width: 768px) {
    .console__item .console__item__label {
        display: none;
    }
}