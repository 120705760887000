.floating-label {
    position: absolute;
    left: calc(10rem/16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    background-color: #fff;
    padding: 0 calc(8rem/16);
    line-height: 1;
    font-size: calc(15rem/16);
}

.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(-190%) scale(.75);
    background-color: #fff;

    @media screen and (min-width: 768px){
        transform: translateY(-168%) scale(.7);
    }
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-190%) scale(.75);
    background-color: #fff;

    @media screen and (min-width: 768px){
        transform: translateY(-168%) scale(.7);
    }
}

.text-area .floating-label {
    top: calc(25rem / 16);
}
.text-area.has-value ~ .floating-label {
    transform: translateY(-190%) scale(.75);
    background-color: #fff;

    @media screen and (min-width: 768px){
        transform: translateY(-25rem) scale(.7);
    }
}