.slider {
    clear: both;
}

/* row--same-height */
.slider--same-height .slick-slide {
    display: flex;
    flex-grow: 1;
    height: auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.slider--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
}
.slider--same-height.slick-slider {
    display: block;
}

/* this div is created automatically by slick-slider */
.slider--same-height .slick-slide > div {
    display: flex;
    flex-grow: 1;
    height: auto;
}

.slider--same-height .slick-slide > div > .slider--same-height__item {
    flex-grow: 1;
    height: auto;
}

