.img-teaser-large{
    position: relative;
    color: #fff;
    overflow: hidden;
}

.img-teaser-large img {
    transition: transform 300ms ease-in-out;
}
.img-teaser-large:hover img {
    transform: scale(1.05);
}

.img-teaser-large:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.0) 50%);
    z-index: 1;
}

.img-teaser-large__body{
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: calc(30rem/16);

    @media screen and (min-width: 768px){
        padding-bottom: calc(80rem/16);
    }
}

.img-teaser__whatsapp{
    width: calc(60rem/16);
    margin: 0 auto;
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        width: calc(80rem/16);
    }
}

.img-teaser-large__title {
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        font-size: calc(35rem/16);
    }
}