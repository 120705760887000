.media-gallery-slider-area.bg-isgold {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(64rem/16);
    }
}

.media-gallery-slider{
    position: relative;
    @media screen and (max-width: 767px){
       padding-bottom: calc(35rem/16);
    }
}

.media-gallery-slider__main{
    line-height: 0;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.08);
    border-radius: calc(20rem/16);
}

.media-gallery-slider__main__item,
.media-gallery-slider__main__item img{
    border-radius: calc(20rem/16);
    font-size: 0;
}

.media-gallery-slider__main__item:hover .btn-play {
    background-color: var(--color-primary);
    color: #fff;
}

.media-gallery-slider__nav{
    line-height: 0;
    margin-top: calc(10rem/16);
    border-radius: calc(10rem/16);
    overflow: hidden;
    
    @media screen and (min-width: 768px){
        width: 70%;
        transform: translateX(5%);
        margin-top: calc(-65rem/16);
    }
    
    @media screen and (min-width: 1320px){
        transform: translateX(-20%);
    }
}

.media-gallery-slider__nav .slick-slide{
    position: relative;
    cursor: pointer;
}

.media-gallery-slider__nav .slick-slide:before{
    content: '';
    position: absolute;
    height: calc(4rem/16);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-primary);
    z-index: 1;
    opacity: 0;
    transition: all 250ms ease;
}

.media-gallery-slider__nav .slick-slide.slick-current:before,
.media-gallery-slider__nav .slick-slide:hover:before{
    opacity: 1;
}

.media-gallery-slider__navigation{
    position: absolute;
    bottom: 0;
    right: 50%;
    
    @media screen and (min-width: 768px){
        bottom: calc(8rem/16);
        right: calc(34rem/16);
    }
    
    @media screen and (max-width: 767px){
        background: #fff;
        padding: calc(2rem/16) calc(25rem/16);
        border-radius: calc(20rem/16);
        font-size: calc(12rem/16);
        transform: translateX(50%);
    }
}

.bg-isgold .media-gallery-slider__navigation {
    color: var(--color-text-default);
}

.media-gallery-slider__paging{
    text-align: center;

    @media screen and (min-width: 768px) {
        margin-right: calc(24rem/16);
        margin-top: calc(8rem/16);
        pointer-events: none;
    }
}

.media-gallery-slider__navigation .slider__arrow{
    position: absolute;
    top: calc(-1rem/16);
}

.bg-isgold .media-gallery-slider__navigation .slider__arrow{
    color: var(--color-primary);
    background: var(--color-white);
}

.media-gallery-slider__navigation .slider__arrow.slick-prev{
    left: 0;
    top: calc(-1rem/16);
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px){
        left: calc(-56rem / 16);
        top: calc(-2rem/16);
        margin-top: 0;
    }
}

.media-gallery-slider__navigation .slider__arrow.slick-next{
    right: 0;
    top: calc(-1rem/16);
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px){
        right: calc(-34rem/16);
        margin-top: 0;
    }
}