.img-teaser {
    position: relative;
    font-size: calc(15rem / 16);
    line-height: calc(24 / 20);
    color: #fff;
    border-radius: calc(20rem / 16);
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .08);
    overflow: hidden;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.img-teaser:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.0) 50%);

    z-index: 1;
    border-radius: calc(20rem/16);
    transition: all 300ms ease-in-out;
}

.img-teaser--webcam:before {
    opacity: 0;
}
.img-teaser--webcam:hover.img-teaser--webcam:before {
    opacity: 0;
}

.img-teaser .embed-responsive{
    border-radius: calc(20rem/16);
}

.img-teaser__body{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(15rem/16);
    z-index: 2;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (min-width: 768px){
        padding: calc(30rem/16);
    }
}

.img-teaser__title{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: 0;
    @media screen and (min-width: 768px){
       font-size: calc(40rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(20rem/16);
    }
}

.img-teaser img{
    border-radius: calc(20rem/16);
    transition: transform 300ms ease-in-out;
}

.img-teaser:hover img {
    transform: scale(1.05);
}

/* img-teaser-slider */

.img-teaser-slider-area:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
    height: 80%;
}

.img-teaser-slider{
    position: relative;
    z-index: 2;
}

.img-teaser-slider .slick-slide{
    margin: 0 calc(15rem/16);
}

.img-teaser-slider .slick-list{
    overflow: visible;
    max-width: 100%;
}

.img-teaser-slider-area{
    position: relative;
}


/* img-teaser--whatsapp-hero */

.img-teaser--whatsapp-hero .img-teaser__title{
    font-size: calc(22rem/16);
    line-height: calc(30/25);
    text-transform: none;
    width: 90%;
    margin: 0 auto;

    @media screen and (min-width: 1600px){
        width: 70%;
    }

    @media screen and (min-width: 1200px){
        font-size: calc(25rem/16);
    }
}