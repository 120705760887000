/* ordered list of all media queries (for mqpacker)*/
@media screen and (max-width: 767px) { }
@media screen and (min-width: 360px) { }
@media screen and (min-width: 768px) { }
@media screen and (min-width: 1075px) { }
@media screen and (min-width: 1090px) { }
@media screen and (min-width: 1100px) { }
@media screen and (min-width: 1120px) { }
@media screen and (min-width: 1140px) { }
@media screen and (min-width: 1200px) { }
@media screen and (min-width: 1250px) { }
@media screen and (min-width: 1350px) { }
@media screen and (min-width: 1500px) { }
@media screen and (min-width: 1600px) { }
@media screen and (min-width: 1650px) { }
@media screen and (min-width: 1700px) { }
@media screen and (min-width: 1760px) { }
@media screen and (min-width: 1800px) { }