.gradient-box{
    padding: calc(20rem/16);
    background: linear-gradient(0deg, #FFFFFF 0%, #EAE0D2 100%);
    border-radius: 10px;
    font-size: calc(14rem/16);
    font-family: var(--font-default);

    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }
}