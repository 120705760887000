.hero--facilities{
    margin-bottom: calc(40rem/16);
    @media screen and (min-width: 768px){
       margin-bottom: calc(50rem/16);
    }
}

.hero--facilities .hero__title-block{
    width: 100%;
    max-width: calc(900rem/16);
    margin: 0 auto;
}

.hero--facilities .hero__body{
    margin-top: -23%;
    position: relative;

    @media screen and (min-width: 768px){
        margin-top: -23%;
        margin-bottom: calc(100rem/16);
    }
    @media screen and (min-width: 1200px) {
        margin-top: -15%;
    }
}

.hero--facilities .hero__body .img-teaser-slider{
    @media screen and (min-width: 768px){
       margin-top: calc(90rem/16);
    }
}

.hero--facilities .hero__image{
    z-index: -1;
}

.hero--facilities .table--ticket{
    position: relative;
}

.hero--facilities .table--ticket:before,
.hero--facilities .table--facilities:before{
    @media screen and (min-width: 768px){
        content: var(--icon-snow);
        font-family: iconfont;
        position: absolute;
        left: 20%;
        top: calc(115rem/16);
        color: var(--color-primary);
        opacity: 0.1;
        font-size: 800px;
        z-index: -1;
        transform: translateX(-100%);
        line-height: 1;
    }
}

.hero--facilities .table--facilities:before{
    @media screen and (min-width: 768px){
        left: 30%;
    }
}


.hero--facilities .hero__switch{
    margin-bottom: calc(15rem/16);
    
    @media screen and (min-width: 768px){
        margin-bottom: 0;
    }
}

.hero--facilities-title--gold {
    color: var(--color-primary);
}

/*
.table--facilities .table--facilities__difficulty {
    @media screen and (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
*/



/*
.hero--webcam .hero__switch{
    @media screen and (max-width: 767px) {
        text-align: center;
        border-radius: calc(20rem/16);
    }
}

.hero--webcam .hero__switch .nav{
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.hero--webcam .hero__switch .nav-item {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}


*/
