:root {
    --color-primary: #b78d4d;
    --color-secondary: #6c757d;
    --color-text-default: #000000;
    --color-text-muted: #333333;
    --color-background: #f8f6f2;

    --color-primary-gradient-contrast: #b99c71;
    --color-default: #000000;
    --color-white: #FFF;
    --color-light-grey: #F8F9FA;
    --color-dark-grey: #BEC2C6;
    --color-dark: #343A40;

    --color-success: #64B526;
    --color-info: #FFC107;
    --color-warning: #FFD200;
    --color-info-blue: #17A2B8;
    --color-danger: #C60A3F;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #98754e;
    --color-secondary-dark: #424D56;
    --color-success-dark: #57a225;
    --color-info-dark: #ecb007;
    --color-warning-dark: #e2b800;
    --color-danger-dark: #a80a34;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #EAE0D2;
    --color-secondary-light: #6E8090;
    --color-success-light: #6dc528;
    --color-info-light: #FBBD03;
    --color-warning-light: #ffdb07;
    --color-danger-light: #d80a42;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Ubuntu-Regular", sans-serif;
    --font-default-bold: "Ubuntu-Bold", sans-serif;
    --font-default-light: "Ubuntu-Light", sans-serif;

    --font-size-default: calc(18rem/16);
}
