.weather-info-margin{
    margin-top: calc(36rem/16);
}


.weather-info-box{
    background-color: #fff;
    border-color: transparent;
    padding: calc(40rem/16) calc(60rem/16);
    border: 1px solid var(--color-primary);
    border-radius: calc(20rem/16);
    border: transparent;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.08);
    text-align: justify;
    @media screen and (max-width: 767px){
        padding: calc(40rem/16) calc(40rem/16);
        font-size: calc(15rem/16);
    }
}
