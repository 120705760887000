.avalanchereport-container{
    @media screen and (min-width: 768px) {
        padding: 0 calc(69.5rem/16);
    }
}

.avalanchereport-title{
    font-size: calc(30rem/16);
    line-height: calc(36/30);
    word-break: break-word;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        font-size: calc(35rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(41rem/16);
        line-height: calc(49/41);
    }
}

.avalanchereport-title__small{
    font-size: calc(26rem/16);
    line-height: calc(25/26);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(35rem/16);
        line-height: calc(42/32);
    }
}

.avalanchereport-container__current{
    padding-top: calc(18rem/16);

    @media screen and (min-width: 768px) {
        padding-top: calc(83rem/16);
    }
}

.avalanchereport-teaser-current {
    height: auto;
    width: auto;
    background-color: white;
    border-radius: 1.25rem;
    box-shadow: 0 calc(30rem/16) calc(30rem/16) 0 rgba(0,0,0,0.08);
    padding: calc(15rem/16) calc(20rem/16);

    @media screen and (min-width: 1200px){
        width: calc(845rem/16);
        padding: calc(25rem/16) calc(40rem/16);
    }
}

.avalanchereport-img__current{
    width: calc(70rem/16);
    height: calc(70rem/16);

    @media screen and (min-width:768px){
        width: calc(100rem/16);
        height: calc(100rem/16);
    }

    @media screen and (min-width:1400px){
        width: calc(150rem/16);
        height: calc(150rem/16);
    }
}

.avalanchereport-text__current{
    font-family: var(--font-default);
    padding-left: calc(30rem/16);

    @media screen and (min-width: 768px) {
        padding-left: calc(98rem/16);
    }
}
.avalanchereport-text__current .h3{
    margin-bottom: calc(5rem/16);

    @media screen and (max-width: 1400px) {
        font-size: calc(20rem/16);
    }
}
.avalanchereport-text__current .avalanchereport-title__small{
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem/16);
    }
}

.avalanchereport-container__danger{
    margin-top: calc(50rem/16);
    margin-bottom: calc(70rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem/16);
        margin-bottom: calc(150rem/16);
    }
}

.avalanchereport-teaser__danger{
    font-family: var(--font-default);
    padding: calc(25rem/16) 0;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) 0;
        display: flex;
        flex-direction: row;
    }
}

.avalanchereport-img__danger{
    width: calc(90rem/16);
    height: calc(90rem/16);

    @media screen and (min-width:1400px){
        width: calc(120rem/16);
        height: calc(120rem/16);
    }

    @media screen and (max-width:768px){
        width: calc(100rem/16);
        height: calc(100rem/16);
        align-self: center;
        padding-bottom: calc(20rem/16);
    }
}

.avalanchereport-text__danger{
    @media screen and (min-width: 768px) {
        padding-left: calc(97rem/16);
    }
}

.avalanchereport-text__danger .h3{
    font-family: var(--font-default-bold);
    @media screen and (max-width: 1400px) {
        font-size: calc(20rem/16);
    }
}

.avalanchereport-text__danger .avalanchereport-forecast{
    font-family: var(--font-default-bold);
}