.route-teaser{
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.08);
    background-color: #fff;
    border-radius: calc(20rem/16);

    height: 100%;
    display: flex;
    flex-direction: column;

    overflow: hidden;
}
.route-teaser .route-teaser__img .embed-responsive-item{
    width: auto;
    min-height: 100%;

}

.route-teaser__img img{
    border-radius: 20px 20px 0 0;
    transition: transform 300ms ease-in-out;
}
.route-teaser:hover .route-teaser__img img {
    transform: scale(1.05);
}

.route-teaser__body{
    padding: calc(20rem/16);
    @media screen and (min-width: 1200px){
        padding: calc(20rem/16) calc(40rem/16) calc(25rem/16);
    }
}

.route-teaser__btns{
    padding: 0 calc(20rem/16) calc(30rem/16);

    @media screen and (min-width: 1200px){
        padding: 0 calc(40rem/16) calc(60rem/16);
    }
}

.route-teaser__btns .btn{
    @media screen and (max-width: 767px){
        font-size: calc(12rem/16);
        padding: calc(10rem/16) calc(15rem/16);
    }
}

.route-teaser__title{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
    line-height: calc(35/30);
    letter-spacing: calc(0.75rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(30rem/16);
    }
}
.route-teaser__text{
    line-height: calc(24/16);

    @media screen and (min-width: 768px){
        line-height: calc(26/18)
    }
}
