.content-block,
.pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem/16);
    }
}


.content-block-small,
.pimcore_area_content > .content-block-small,
.pimcore_area_content + .pimcore_area_content > .content-block-small,
.pimcore_area_content-wysiwyg + .pimcore_area_content > .content-block{

    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}



.slide + .content-block,
.slide + .content-block-small,
.slide-lg + .content-block,
.slide-lg + .content-block-small,
.slide-medium + .content-block,
.slide-medium + .content-block-small,
/*.pimcore_area_content + .pimcore_area_content-wysiwyg > .content-block.slide-medium,
.pimcore_area_content + .pimcore_area_content-wysiwyg > .content-block-small.slide-medium,
.pimcore_area_content-wysiwyg + .pimcore_area_content-wysiwyg > .content-block,
.pimcore_area_content-wysiwyg + .pimcore_area_content-wysiwyg > .content-block-small*/{
    margin-top: 0;
    
    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}

.slide-bg + .pimcore_area_content > .content-block,
.slide-bg + .pimcore_area_content > .content-block-small{
    margin-top: 0;
}