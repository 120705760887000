.lg-backdrop {
    background-color: var(--color-background);
}

.lg-download,
#lg-counter {
    display: none;
}

.lg-outer .lg-img-wrap {
    padding: 50px;
}

.lg-outer .lg-inner {
    background: #000;
}

.lg-outer .lg-video {
    max-height: 100vh;
    width: 100%;
    height: inherit;
    max-width: 100vw;
    padding-bottom: 0 !important;
}

.lg-outer .lg-video-cont {
    max-width: 100% !important;
    height: 84vh;
    @media screen and (min-width: 390px) {
        height: 88vh;
    }
    @media screen and (min-width: 768px) {
        height: 89vh;
    }
}

.lg-toolbar .lg-close:after {
    font-family: iconfont;
    color: var(--color-dark-dark);
    content: var(--icon-close);
}

.lg-toolbar .lg-icon {
    background-color: var(--color-background);
}

.lg-actions .lg-next:before,
.lg-actions .lg-prev:after {
    display: block !important;
    font-family: iconfont;
    content: var(--icon-arrow-left);
    color: var(--color-dark-dark);
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    border-radius: 0;
    margin-top: -20px;
    background-color: var(--color-background);
}

.lg-actions .lg-next:before {
    content: var(--icon-arrow-right);
}

.lg-toolbar {
    background-color: transparent;
}


.lg-sub-html {
    color: var(--color-secondary);
    font-size: 1.75rem; /* default */
    font-family: var(--font-special);
    background-color: var(--color-background);
}

.lg-sub-html .lightbox-caption__headline {
    font-size: 1.75rem; /* presse etc */
}

.lg-sub-html p {
    font-size: 1rem; /* presse etc */
    font-family: var(--font-default);
}
