.notice-banner{
    width: 100%;
    @media screen and (max-width: 767px){
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
    }
}
.notice-banner__content{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: var(--color-danger);
    text-align: center;
    font-size: calc(18rem/16);
    color: #fff;
    min-height: calc(60rem/16);
}

.notice-banner__text{
    padding: calc(10rem/16) calc(16rem/16);
    & p {
        margin-bottom: 0;
    }
    @media screen and (max-width: 767px) {
        line-height: 1.4;
        padding-right: calc(40rem/16);
    }
    @media screen and (min-width: 768px) {
        display: flex;
        column-gap: calc(4rem/16);
    }
}

.notice-banner__close{
    position: absolute;
    top: calc(7rem/16);
    right: calc(20rem/16);
    color: #fff;
    @media screen and (max-width: 767px) {
        top: 0;
        right: calc(8rem/16);
    }
}
.notice-banner__close .icon-close{
    color: #fff;
}