.hero{
    position: relative;
}

.hero__title-block{
    /*text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.55);*/
    position: relative;
    text-shadow: none!important;
}

.hero__title-block:before {
    content: attr(data-shadow-text);
    color: transparent;
    text-shadow: 0 0 calc(20rem / 16) rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
}
.hero__title:after {
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.55);
}

.hero-title-block__title{
    @media screen and (max-width: 767px){
        -webkit-text-stroke: 1px var(--color-primary-dark);
    }
}

.hero__title-block__title{
    font-size: calc(40rem/16);
    letter-spacing: calc(5rem/16);
    line-height: calc(46/40);

    @media screen and (min-width: 768px){
        letter-spacing: calc(10rem/16);
    }
}

.hero__title-block__subtitle{
    font-size: calc(30rem/16);
    letter-spacing: calc(2rem/16);
    line-height: 1;
}

.hero--weather .hero__body{
    width: 100%;
    position: relative;

    @media screen and (min-width: 768px){
        position: absolute;
        bottom: calc(32rem/16);
        left: 0;
        right: 0;
    }

    @media screen and (max-width: 767px){
        margin-top: -23%;
    }
}

.hero--simple__switch {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-80%);
}

.hero--simple .hero__title-block{
    margin-top: calc(-100rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(-140rem/16);
    }
}

.hero-title-switch{
    @media screen and (max-width: 767px){
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 0 calc(15rem/16);
    }
}



/* hero content */


.hero--content{
    margin-bottom: calc(70rem/16);

    @media screen and (min-width: 768px){
        /*margin-bottom: calc(90rem/16);*/
        min-height: 100vh;
        margin-bottom: 0;
    }

    /*@media screen and (max-width: 767px){
       z-index: -1;
    }*/
}


.hero--content .hero__image:after{
    display: none;
}

.hero__content,
.hero--content .hero__body{
    position: relative;
    z-index: 3;
    margin-top: -23%;
    margin-bottom: calc(-40rem/16);

    @media screen and (min-width: 768px){
        margin-top: -23%;
        margin-bottom: calc(-120rem/16);
    }
    @media screen and (min-width: 1200px) {
        margin-top: -16%;
    }
}

.hero__title{
    font-size: calc(40rem/16); /*35*/
    letter-spacing: calc(5rem/16);
    /*text-transform: uppercase;*/

    @media screen and (min-width: 768px){
        font-size: calc(65rem/16); /*50*/
        letter-spacing: calc(14.3rem/16);
    }
}


.hero__subtitle{
    font-size: calc(15rem/16); /*20*/
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    text-shadow: 0 0 2rem rgb(0 0 0 / 35%);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16); /*30*/
        letter-spacing: calc(2rem/16);
    }

    @media screen and (max-width: 767px){
        margin-top: calc(10rem/16);
        line-height: 1.4;
    }
}

/*-------------------------*/
.hero__subtitle--white {
    color: white;
}
.hero__headline--center {
    text-align: center;
    display: inline-block;
}

.hero__headline--center .hero__title--edit:before {
    left: 50%;
    transform: translateX(-50%);
}

.hero__text{
    padding: calc(20rem/16) calc(30rem/16) calc(30rem/16);
    background-color: #fff;
    border-radius: calc(20rem/16);
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.08);

    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
        font-size: calc(16rem/16);
    }
    @media screen and (min-width: 768px){
        padding: calc(68rem/16) calc(50rem/16);
    }
    @media screen and (min-width: 992px) {
        min-height: calc(345rem/16);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }
    @media screen and (min-width: 1200px) {
        min-height: calc(360rem/16);
        padding: calc(68rem/16) calc(85rem/16);
        font-size: calc(18rem/16);
    }
    @media screen and (min-width: 1500px) {
        padding: calc(100rem/16) calc(85rem/16);
    }
}

.hero__text p {
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
}

.hero__text.hero__text--sm{
    padding: calc(20rem/16) calc(30rem/16);
    font-size: calc(16rem / 16);
    line-height: calc(18/16);
}

.hero--content__media{
    border-radius: calc(20rem/16);
    overflow: hidden;

    @media screen and (min-width: 768px){
        border-radius: 0 calc(20rem/16) calc(20rem/16) 0;
    }
}
.hero--content__media .vjs-poster{
    background-size: cover;
}

.hero--content__media .video-js .vjs-big-play-button{
    width: calc(60rem/16);
    height: calc(60rem/16);
    font-size: calc(21rem/16);
}

.hero__image{

    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: calc(600rem/16);
    z-index: -2;

    @media screen and (min-width: 768px){
        height: 100vh;
    }
}

.hero__image.hero__image--mobile{
    height: calc(400rem/16);
    @media screen and (min-width: 768px){
        height: 100vh;
    }
}

.hero--pressImages .hero__image {
    height: calc(400rem/16);
    @media screen and (min-width: 768px) {
        height: calc(600rem/16);
    }
}

.hero__image:not(.hero__image--no-overlay):before {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
    bottom: calc(-1rem/16);
    left: 0;
    right: 0;
    height: calc(195rem/16);
}

.hero__badge {
    position: absolute;
    top: calc(100rem / 16);
    right: calc(20rem / 16);
    z-index: 5;

    color: var(--color-primary);
    font-size: calc(14rem / 16);
    line-height: calc(24/20);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        top: calc(135rem / 16);
        right: calc(40rem / 16);
    }

    @media screen and (min-width: 1500px) {
        font-size: calc(20rem / 16);
    }
}
.hero__badge__icon {
    font-size: calc(105rem / 16);
    color: var(--color-primary-light);
    text-shadow: 0 0 calc(20rem / 16) rgba(0,0,0,0.5);

    @media screen and (min-width: 768px) {
        font-size: calc(125rem / 16);
        text-shadow: 0 0 calc(40rem / 16) rgba(0,0,0,0.5);
    }

    @media screen and (min-width: 1500px) {
        font-size: calc(176rem / 16);
    }

}
.hero__badge__text {
    text-shadow: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
}

.hero__media{
    @media screen and (max-width: 767px){
        position: relative;
    }
}

.hero__media-text-content{
    @media screen and (min-width: 768px){
        margin-bottom: calc(155rem/16);
    }
}

/* relative content */
.hero--relative-content {
    position: relative;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}

.hero--relative-content .hero__image {
    overflow: hidden;
    height: 60vh;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        z-index: 1;
        margin-bottom: -30vh;
    }
}
.hero--relative-content .hero__image:not(.hero__image--no-overlay):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: auto;
    background: linear-gradient(180deg,  rgba(255,255,255,0) 0%,rgba(255,255,255,.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

body.isBgBeige .hero__image:not(.hero__image--no-overlay):before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    height: 50%;
    background: linear-gradient(0deg, #F9F6F2 0%, rgba(255,255,255,0) 100%);
}

.hero__content-wrapper {
    width: 100%;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        min-height: 101vh;
    }
    @media screen and (min-width: 768px){
       pointer-events: none;
    }
}

.hero__content-wrapper.hero__content-wrapper--summer{
    @media screen and (min-width: 768px){
        pointer-events: auto;
    }
}

.hero--video .hero__content-wrapper{
    @media screen and (min-width: 768px){
        position: absolute;
    }
}

.hero__content-relative {
    position: relative;

    @media screen and (min-width: 768px) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(170rem/16);
        padding: 0;

    }

    @media screen and (min-width: 1500px) {
        height: calc(246rem/16);

    }

}

.hero__content-relative:after {
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 45%;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
        margin-bottom: calc(-1rem/16);
    }
}

.hero__content-relative .hide-on-play{
    @media screen and (min-width: 768px){
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        margin-top: calc(-260rem/16);
    }
    @media screen and (min-width: 1500px){
        margin-top: calc(-290rem/16);
    }
}

.hero-title-block {
    position: relative;
}
.hero-title-block:before {
    content: attr(data-shadow-text);
    color: transparent;
    text-shadow: 2px 2px 15px rgb(0 0 0 / 50%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    @media screen and (min-width: 768px){
        text-shadow: 2px 2px 20px rgb(0 0 0 / 70%);
    }
}

/*--------------hero-title-block component--------------*/
.hero__title--edit, .hero__subtitle--edit {
    @media screen and (min-width: 768px) {
        width: calc(927rem/16);
    }
}
.hero__title--edit {
    font-size: clamp(26rem/16, 7vw, 40rem/16);
    hyphens: auto;
    margin-bottom: calc(5rem/16);
    -webkit-text-stroke: 1px var(--color-primary-dark);
    position: relative;

    @media screen and (max-width: 767px){
        line-height: 1.1;
    }

    @media screen and (min-width: 768px) {
        font-size: calc(71rem/16);
        margin-bottom: calc(13rem/16);
    }
}

.hero__title--edit:before {
    content: attr(data-shadow-text);
    color: transparent;
    text-shadow: 2px 2px 9px rgb(0 0 0 / 70%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;

    @media screen and (max-width: 767px) {
    //text-shadow: 0 0 0.3rem rgb(0 0 0 / 35%);
        text-shadow: calc(4rem/16) calc(4rem/16) 0.5rem rgb(0 0 0 / 35%);
    }
}

.hero__subtitle--edit:before {
    content: attr(data-shadow-text);
    color: transparent;
    text-shadow: 0 calc(2rem/16) calc(15rem/16) 0 #000000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
}

.h1.hero-title-block__title, .h1.hero-title-block {
    @media screen and (min-width: 768px) {
        font-size: calc(35rem/16);
    }
}
.hero-animate {
    @media screen and (min-width: 768px){
        animation: 2000ms ease-in-out 0s 1 scaleIn;
        animation-delay: 800ms;
        animation-fill-mode: forwards;
        transform: scale(1.3); /*1.3*/
        opacity: 0;
        /*transform: translateY(-30%);*/
    }


    @media screen and (max-width: 767px){
        position: absolute;
        bottom: 0;
    }
}

@keyframes scaleIn {
    0% {
        transform: scale(1.3);
        opacity: 0;
    }
    100% {
        transform: scale(1); /*entspricht 35px*/
        opacity: 1;
    }
}

.hero__switch.has-animation{
    @media screen and (min-width: 768px){
        animation: 2000ms ease-in-out 0s 1 fadeIn;
        animation-delay: 800ms;
        animation-fill-mode: forwards;
        opacity: 0;
        transform: translateY(-40%);
    }

}

@keyframes fadeIn {
    0% {
        transform: translateY(40%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.hero--portal {
    @media screen and (min-width: 768px) {
        margin-bottom: 7%;
    }
}
.hero--portal .hero-title-block{
    display: inline-block;
    position: relative;
    transform: scale(1);
}

.hero--portal .hero__content-wrapper{
    @media screen and (max-width: 767px){
       margin-top: calc(-180rem/16);
    }
}

.hero--portal .hero__image {
    @media screen and (max-width: 767px) {
        height: 68vh;
    }
}

.hero--simple .hero-title-switch{
    @media screen and (max-width: 767px){
        position: relative;
    }
}

.hero--simple .hero-animate{
    @media screen and (max-width: 767px){
        left: 50%;
        transform: translateX(-50%);
    }
}

/* hero video */

.hero__video{
    z-index: -2;
    @media screen and (min-width: 768px){
        height: 100vh;
        position: relative;
        overflow: hidden;
    }
}
.hero__video .hero__video__video,
.hero__video iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: calc(90vh - calc(72rem / 16));
    width: 100%;
    overflow: hidden;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        height: 100vh;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        width: auto;
    }
}

.hero__video iframe {
    @media screen and (min-width: 768px){
        height: 100vh;
        transform: translateX(-50%) translateY(-50%) scale(1.15);
    }
}

.hero__video .video-js .vjs-tech {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    width: auto;
    height: auto;
}
.hero__video .hero__video__video--no-scale.video-js .vjs-tech {
    top: auto;
    left: auto;
    transform: none;
    min-width: unset;
    min-height: unset;
    width: 100%;
    height: 100%;
}
.hero__video .vjs-big-play-button{
    display: none;
}
.hero__video .vjs-poster {
    transition: opacity 200ms ease-in-out;
    background-size: cover;
    display: block;
}
.hero__video .vjs-has-started .vjs-poster {
    opacity: 0;
}
.hero__video-container .hero__content {
    transition: opacity 200ms ease-in-out;
}

.hero__volume-toggle{
    position: absolute;
    z-index: 100;
    bottom: 5%;
    right: 2%;
    opacity: 0;
    transition: opacity .3s ease-in;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(52rem/16);
    height: calc(52rem/16);
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    @media screen and (max-width: 767px){
        opacity: 1;
    }
    @media screen and (max-height: 600px) and (min-width: 786px) {
        width: calc(45rem/16);
        height: calc(45rem/16);
    }
}

.hero__volume-toggle-icon{
    color: var(--color-primary);
    font-size: calc(20rem/16);
    line-height: 1;
}

.hero:hover .hero__volume-toggle{
   opacity: 1;
}

/*
.hero.is-playing .hide-on-play {opacity: 0; animation: none}*/

.hero.is-playing .hero__image {opacity: 0; animation: none}

.hero__title-gold {
    color: var(--color-primary);
}

/*---------- portal version ---------*/
.portal-main .hero--portal {
    @media screen and (min-width: 768px) {
        margin-bottom: 0!important;
    }
}


