.lightbox-teaser{
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.08);
    background-color: #fff;
    border-radius: calc(20rem/16);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.lightbox-teaser__img{
    border-radius: calc(20rem / 16) calc(20rem / 16) 0 0;
}

.lightbox-teaser img {
    transition: transform 300ms ease-in-out;
}
.lightbox-teaser:hover img {
    transform: scale(1.05);
}

.lightbox-teaser__content {
    padding: calc(20rem/16);
}
.lightbox-teaser__head {
    cursor: pointer;
}
.lightbox-teaser__head__play {
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    border-radius: 50%;
    background: #fff;
    color: var(--color-primary);
    border: calc(1rem / 16) solid #fff;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(20rem / 16);
    transition: color 200ms ease-in-out, background 200ms ease-in-out;
}
.lightbox-teaser__head:hover .lightbox-teaser__head__play {
    background: var(--color-primary);
    color: #fff;
}
.lightbox-teaser__head__play__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}