@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1732539883123/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1732539883123/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1732539883123/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-2103:before { content: "\EA01" }
.icon-2206:before { content: "\EA02" }
.icon-601:before { content: "\EA03" }
.icon-602:before { content: "\EA04" }
.icon-604:before { content: "\EA05" }
.icon-606:before { content: "\EA06" }
.icon-607:before { content: "\EA07" }
.icon-608:before { content: "\EA08" }
.icon-609:before { content: "\EA09" }
.icon-610:before { content: "\EA0A" }
.icon-611:before { content: "\EA0B" }
.icon-612:before { content: "\EA0C" }
.icon-614:before { content: "\EA0D" }
.icon-617:before { content: "\EA0E" }
.icon-address:before { content: "\EA0F" }
.icon-addthis:before { content: "\EA10" }
.icon-arrow-left:before { content: "\EA11" }
.icon-arrow-right:before { content: "\EA12" }
.icon-badge:before { content: "\EA13" }
.icon-calendar:before { content: "\EA14" }
.icon-car:before { content: "\EA15" }
.icon-close:before { content: "\EA16" }
.icon-download:before { content: "\EA17" }
.icon-drag:before { content: "\EA18" }
.icon-dropdown:before { content: "\EA19" }
.icon-elements:before { content: "\EA1A" }
.icon-facebook-bg:before { content: "\EA1B" }
.icon-facebook:before { content: "\EA1C" }
.icon-hourglass:before { content: "\EA1D" }
.icon-icon_newsletter:before { content: "\EA1E" }
.icon-instagram-bg:before { content: "\EA1F" }
.icon-language:before { content: "\EA20" }
.icon-lift:before { content: "\EA21" }
.icon-linkedin-bg:before { content: "\EA22" }
.icon-linkedin:before { content: "\EA23" }
.icon-mail:before { content: "\EA24" }
.icon-map:before { content: "\EA25" }
.icon-newsletter:before { content: "\EA26" }
.icon-parking:before { content: "\EA27" }
.icon-pdf:before { content: "\EA28" }
.icon-phone:before { content: "\EA29" }
.icon-pinterest:before { content: "\EA2A" }
.icon-piste:before { content: "\EA2B" }
.icon-play:before { content: "\EA2C" }
.icon-poi:before { content: "\EA2D" }
.icon-quote:before { content: "\EA2E" }
.icon-search:before { content: "\EA2F" }
.icon-shop-cart:before { content: "\EA30" }
.icon-ski-board:before { content: "\EA31" }
.icon-ski:before { content: "\EA32" }
.icon-snapchat-bg:before { content: "\EA33" }
.icon-snow-down:before { content: "\EA34" }
.icon-snow-fresh:before { content: "\EA35" }
.icon-snow-up:before { content: "\EA36" }
.icon-snow:before { content: "\EA37" }
.icon-temperature:before { content: "\EA38" }
.icon-ticket:before { content: "\EA39" }
.icon-twitter-bg:before { content: "\EA3A" }
.icon-twitter:before { content: "\EA3B" }
.icon-volume-off:before { content: "\EA3C" }
.icon-volume-on:before { content: "\EA3D" }
.icon-weather:before { content: "\EA3E" }
.icon-webcam:before { content: "\EA3F" }
.icon-website:before { content: "\EA40" }
.icon-whatsapp-bg:before { content: "\EA41" }
.icon-whatsapp:before { content: "\EA42" }


:root {
--icon-2103: "\EA01";
    --icon-2206: "\EA02";
    --icon-601: "\EA03";
    --icon-602: "\EA04";
    --icon-604: "\EA05";
    --icon-606: "\EA06";
    --icon-607: "\EA07";
    --icon-608: "\EA08";
    --icon-609: "\EA09";
    --icon-610: "\EA0A";
    --icon-611: "\EA0B";
    --icon-612: "\EA0C";
    --icon-614: "\EA0D";
    --icon-617: "\EA0E";
    --icon-address: "\EA0F";
    --icon-addthis: "\EA10";
    --icon-arrow-left: "\EA11";
    --icon-arrow-right: "\EA12";
    --icon-badge: "\EA13";
    --icon-calendar: "\EA14";
    --icon-car: "\EA15";
    --icon-close: "\EA16";
    --icon-download: "\EA17";
    --icon-drag: "\EA18";
    --icon-dropdown: "\EA19";
    --icon-elements: "\EA1A";
    --icon-facebook-bg: "\EA1B";
    --icon-facebook: "\EA1C";
    --icon-hourglass: "\EA1D";
    --icon-icon_newsletter: "\EA1E";
    --icon-instagram-bg: "\EA1F";
    --icon-language: "\EA20";
    --icon-lift: "\EA21";
    --icon-linkedin-bg: "\EA22";
    --icon-linkedin: "\EA23";
    --icon-mail: "\EA24";
    --icon-map: "\EA25";
    --icon-newsletter: "\EA26";
    --icon-parking: "\EA27";
    --icon-pdf: "\EA28";
    --icon-phone: "\EA29";
    --icon-pinterest: "\EA2A";
    --icon-piste: "\EA2B";
    --icon-play: "\EA2C";
    --icon-poi: "\EA2D";
    --icon-quote: "\EA2E";
    --icon-search: "\EA2F";
    --icon-shop-cart: "\EA30";
    --icon-ski-board: "\EA31";
    --icon-ski: "\EA32";
    --icon-snapchat-bg: "\EA33";
    --icon-snow-down: "\EA34";
    --icon-snow-fresh: "\EA35";
    --icon-snow-up: "\EA36";
    --icon-snow: "\EA37";
    --icon-temperature: "\EA38";
    --icon-ticket: "\EA39";
    --icon-twitter-bg: "\EA3A";
    --icon-twitter: "\EA3B";
    --icon-volume-off: "\EA3C";
    --icon-volume-on: "\EA3D";
    --icon-weather: "\EA3E";
    --icon-webcam: "\EA3F";
    --icon-website: "\EA40";
    --icon-whatsapp-bg: "\EA41";
    --icon-whatsapp: "\EA42";
    
}