.content-teaser{
    position: relative;
    font-size: calc(14rem/16);
    line-height: calc(24/14);
    border-radius: calc(20rem/16);
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.08);
    background-color: #fff;
    overflow: hidden;
}

.content-teaser__img img {
    transition: transform 300ms ease-in-out;
    transform-origin: left center;
}
.content-teaser:hover .content-teaser__img img {
    transform: scale(1.05);
}

.content-teaser__body{
    padding: calc(25rem/16) calc(30rem/16);
}

.content-teaser__title{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(20rem/16);
}

.content-teaser__img img{
    border-radius: calc(20rem/16) calc(20rem/16) 0 0;
}

/* Content teaser slider */

.content-teaser-slider .slick-slide{
    margin: 0 calc(20rem/16);
}

.content-teaser-slider .slick-list{
    overflow: visible;
    max-width: 100%;
    
    @media screen and (max-width: 767px){
        padding-bottom: calc(80rem/16);
        margin-bottom: calc(-80rem/16);
    }
}

.content-teaser-slider .hold-and-drag{
    @media screen and (max-width: 767px){
       pointer-events: none;
    }
}