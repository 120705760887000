@font-face {
    font-family: 'Ubuntu-Bold';
    src: url('/static/fonts/ubuntu-bold-webfont.woff2') format('woff2'),
    url('/static/fonts/ubuntu-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu-Regular';
    src: url('/static/fonts/ubuntu-regular-webfont.woff2') format('woff2'),
    url('/static/fonts/ubuntu-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu-Light';
    src: url('/static/fonts/ubuntu-light-webfont.woff2') format('woff2'),
    url('/static/fonts/ubuntu-light-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
