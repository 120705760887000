.video-js .vjs-big-play-button{
    width: calc(100rem/16);
    height: calc(100rem/16);
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #fff;
    color: var(--color-primary);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: background-color 0.15s, color 0.15s;
}

.video-js .vjs-big-play-button{
    transition: background-color 0.15s, color 0.15s;
    
    @media screen and (max-width: 767px) {
        width: calc(70rem/16);
        height: calc(70rem/16);
        font-size: calc(25rem/16);
    }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before{
    content: var(--icon-play);
    font-family: iconfont;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button{
    background-color: var(--color-primary);
    color: #fff;
}