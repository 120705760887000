.subnav{
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background: #fff;
    padding: calc(30rem/16) 0;
    
    @media screen and (min-width: 768px){
        display: none;
    }

}

.subnav--level-1{
    background: linear-gradient(270deg, #FFFFFF 0%, #F6F6F6 100%);
    @media screen and (min-width: 768px){
       width: calc(320rem/16);
    }

    @media screen and (max-width: 767px){
        position: fixed;
        transform: translateX(-100%);
        transition: transform 250ms ease;
        bottom: 0;
        left: 0;
    }
}

.navbar-main-item.is-open>.subnav--level-1{
    @media screen and (min-width: 768px){
        display: block;
    }
    
    @media screen and (max-width: 767px){
        transform: translateX(0);
    }
}

.subnav-nav-item{
    font-size: calc(16rem/16);
    padding: calc(5rem/16) calc(25rem/16) calc(5rem/16) calc(30rem/16);

    @media screen and (min-width: 768px){
        padding: calc(5rem/16) calc(30rem/16);
    }
}

.subnav-nav-item{
    font-family: var(--font-default);
}

.subnav-list.is-active>.subnav-nav-item{
    opacity: 0.4;
}

.subnav-list.is-active>.subnav-nav-item.is-open{
    opacity: 1;
}

.subnav-nav-link{
    position: relative;
}

.subnav-title{
    font-size: calc(19rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(5rem/16);
    padding: 0 calc(30rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.subnav-nav-link{
    padding: 0;
}


@media screen and (max-width: 767px){
    .btn-subnav-back{
        margin-bottom: calc(40rem/16);
        margin-right: calc(30rem/16);
        font-family: var(--font-default-light);
    }

    .btn-subnav-back__icon{
        vertical-align: 0;
        margin-right: calc(8rem/16);
        font-size: calc(12rem/16);
    }
}

.btn-subnav-toggle {
    width: calc(25rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(13rem/16);
    
    @media screen and (min-width: 768px){
       display: inline-block;
    }

    @media (hover: none) and (pointer: coarse) {
        display: flex;

    }

    @media (min-width: 768px) and (max-width: 1025px){
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: calc(40rem/16);
        z-index: 10;
    }
}
.btn-subnav-toggle.btn-subnav-toggle__main-link {
    z-index: -1;
}

/* subnav level 2 */

.subnav--level-2{
    background: linear-gradient(270deg, #FFFFFF 0%, #F6F6F6 100%);
    @media screen and (min-width: 768px){
        width: calc(280rem/16);
    }

    @media screen and (max-width: 767px){
        position: fixed;
        transform: translateX(300%);
        transition: transform 250ms ease;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
}

.subnav-nav-item.is-open>.subnav--level-2{
    @media screen and (min-width: 768px){
        display: block;
    }
    
    @media screen and (max-width: 767px){
       transform: translateX(0);
    }
}
