.video-button {
    position: relative;
    z-index: 99;
}
.video-button__circle {
    width: calc(42rem/16);
    height: calc(42rem/16);
    background: linear-gradient(228.76deg, #D2A96C 0%, var(--color-primary) 100%);
    border-radius: 100%;
    z-index:99;
    position: absolute;
    transform: translateY(-50%);

    @media screen and (min-width: 768px) {
        width: calc(52rem/16);
        height: calc(52rem/16);
        background: linear-gradient(228.76deg, #D2A96C 0%, var(--color-primary) 100%);
        border-radius: 100%;
        z-index:99;
    }
}

.video-button__icon {
    color: white;
    font-size: calc(14rem/16);
    position: absolute;
    top: 50%;
    left: 1.15rem;
    transform: translateY(-50%);
    z-index: 99;

    @media screen and (min-width: 768px) {
        left: 1.45rem;
    }
}
.video-button__text {
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    color: var(--color-primary);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: calc(2rem/16) calc(19.5rem/16) calc(2rem/16) calc(52.5rem/16);
    background-color: white;
    border-radius: calc(27.5rem/16);
    z-index: -1;
    opacity: 1;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
        padding: calc(4rem/16) calc(19.5rem/16) calc(4rem/16) calc(62.5rem/16);
        font-size: 1rem;
    }
}
.video-button__button {
    position: relative;
}
.video-button__button:hover .video-button__text {
    background: var(--color-primary);
    color: white;
    transition: 0.3s ease-in;
}

.video-button__button:hover .video-button__circle {
    /*transform: scale(1);
    animation: pulse 2s infinite;*/
}



@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(174, 139, 87, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(174, 139, 87, 0);
    }

    100% {
        transform: scale(0.95);
    }
}


/*pulse animation*/

.video-button__circle-pulse {
    width: calc(42rem/16);
    height: calc(42rem/16);
    border-radius: 50%;
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: opacity 100ms ease;

    @media screen and (min-width: 768px) {
        width: calc(52rem/16);
        height: calc(52rem/16);
    }
}

.video-button__button .video-button__circle-pulse {
    opacity: 1;
}
.video-button__button:hover .video-button__circle-pulse {
    border: calc(1rem/16) solid white;
}
.video-button__button:hover .pulse-animation {
    animation-name: pulse_animation;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes pulse_animation {
    0% { transform: scale(1); opacity: 0;}
    50% { transform: scale(1.2); opacity: 1;}
    75% { transform: scale(1.4); }
    100% { transform: scale(1.6); opacity: 0; }
}


/*.video-button--center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}*/

/*.video-button--center {
    text-align: -webkit-center;
    margin-left: -19.3%;
}

.video-button--center .video-button__text, .video-button--center .video-button__circle-pulse,
.video-button--center .video-button__icon {
    left: 50%;
    transform: translateY(-50%);
    margin-left: -8.3%;
}

.video-button--center .video-button__circle {
    margin-left: 4%;
}
.video-button--center .video-button__icon {
    margin-left: 2.4%;
}*/
