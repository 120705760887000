.weather-slide{
    padding: calc(25rem/16) 0 0 0;

    @media screen and (min-width: 768px){
        padding: 0 calc(50rem/16) 0;
    }
}

.weather-item {
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(10rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(12rem/16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(16rem/16);
    }
}
.weather-item + .weather-item {
    margin-left: calc(8rem / 16);

    @media screen and (min-width: 1200px) {
        margin-left: calc(10rem / 16);
    }

    @media screen and (min-width: 1500px){
        margin-left: calc(28rem / 16);
    }
}
.weather-item__img {
    width: auto;
    margin: calc(8rem / 16) 0;
    max-width: calc(45rem / 16);
    height: calc(40rem/16);

    @media screen and (min-width: 768px) {
        height: calc(28rem/16);
        width: auto;
        max-width: calc(28rem / 16);
        margin: calc(3rem / 16) 0;
    }

    @media screen and (min-width: 1500px) {
        margin: calc(8rem / 16) 0;
        max-width: calc(45rem / 16);
        height: calc(40rem/16);
    }
}
.weather-item__value {
    font-family: var(--font-default-bold);
    font-weight: 400;
    font-size: calc(22rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }
    @media screen and (min-width: 1500px) {
        font-size: calc(22rem/16);
    }

    @media screen and (max-height: 729px) and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.weather-item-group {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(14rem / 16);
    }
    @media screen and (min-width: 1600px) {
        font-size: calc(16rem/16);
    }
}
.weather-item-group .text-center.mb-2 {
    @media screen and (max-height: 729px)  and (min-width: 768px) and (max-width: 1599px) {
        margin-bottom: calc(3rem/16)!important;
    }
}
.weather-item-group + .weather-item-group {
    padding-left: calc(12rem / 16);
    margin-left: calc(12rem / 16);
    border-left: calc(1rem / 16) solid rgba(151, 151, 151, 0.1);

    @media screen and (min-width: 768px) {
        padding-left: calc(8rem / 16);
        margin-left: calc(8rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding-left: calc(12rem / 16);
        margin-left: calc(12rem / 16);
    }
}