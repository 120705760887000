.weather-whatsapp-slide__title{
    font-size: calc(25rem/16);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(35rem/16);
    }
    
    @media screen and (max-width: 767px){
       line-height: calc(32/25);
    }
}

.weather-whatsapp-slide__icon{
    font-size: calc(60rem/16);
}