.box{
    padding: calc(20rem/16);
    border: 1px solid var(--color-primary);
    border-radius: calc(20rem/16);
    background-color: rgba(255,255,255,0.6);
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.08);

    font-family: var(--font-default);
    font-size: calc(17rem/16);
    line-height: calc(24/20);
    overflow: hidden;

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(10rem/16);
    }

    @media screen and (min-width: 1500px){
        font-size: calc(20rem/16);
        padding: calc(15rem/16) calc(20rem/16);
    }
}

.box__title{
    font-family: var(--font-default-bold);
    letter-spacing: calc(1.33rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(20rem/16);
    }
}

.box__value{
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);

    @media screen and (min-width: 1500px){
        font-size: calc(30rem/16);
    }
}

.box__value.box__value--big{
    font-size: calc(70rem/16);
    line-height: 1;

    @media screen and (min-width: 1500px){
        font-size: calc(80rem/16);
    }
}

.box__value.box__value--small{
    font-size: calc(18rem/16);
    line-height: 1;

    @media screen and (min-width: 1500px){
        font-size: calc(22rem/16);
    }
}

.box__unit{
    font-family: var(--font-default-bold);
    font-size: calc(21rem/16);
    
    @media screen and (max-width: 767px){
       margin-left: calc(3rem/16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.box__weather-img{
    height: calc(65rem/16);
    width: auto;
    margin: calc(10rem/16) 0;

    @media screen and (min-width: 768px){
        height: calc(38rem/16);
        margin: calc(15rem/16) 0;
    }
    @media screen and (min-width: 1500px){
        height: calc(54rem/16);
    }
}

.box__text{
    font-size: calc(16rem/16);
    line-height: calc(18/16);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }

    @media screen and (min-width: 1500px){
        font-size: calc(16rem/16);
    }
}

.box__link{
    margin: 0 -20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 0 0;
    margin-top: 20px;
}

.box__avalanche-img{
    height: calc(40rem/16);
    width: auto;
}

.box__weather-item {
    margin: 0 auto;

    @media screen and (min-width: 768px) and (max-width: 1200px){
        width: calc(32rem/16);

    }

    @media screen and (min-width: 1750px){
        width: calc(75rem/16);
    }
}

.box__weather-item__time{
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem/16);
    }
}

.box--weather-prognosis__tomorrow{
    position: relative;
}
.box--weather-prognosis__tomorrow:before {
    content: '';
    position: absolute;
    width: calc(1rem/16);
    top: calc(-47rem/16);
    bottom: calc(-15rem/16);
    left: 0;
    background: rgba(0, 0, 0, 0.1);
}

.box.box--weather-prognosis{
    @media screen and (min-width: 768px) and (max-width: 1200px){
        display: flex;
        align-items: center;
    }
}

.box--weather-prognosis__inner{
    @media screen and (min-width: 768px) and (max-width: 1200px){
       width: 100%;
    }
}


.box.box--img{
    position: relative;
    display: block;
    padding: 0;
}

.box--img:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(50rem/16);
    background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=0 );
    z-index: 1;
}

.box--img img{
    border-radius: calc(20rem/16);
}

.box--img__title{
    color: #fff;
    font-family: var(--font-default-bold);
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    text-align: center;
    padding: 0 calc(10rem/16);
}

.box--img__title:hover{
    color: #fff;
}

.box--img__title>.icon{
    vertical-align: calc(-2rem/16);
    margin-right: calc(3rem/16);
}


/* box status */

.box--status{
    display: block;
}

.box--status .box__title{
    letter-spacing: 0;
}

.box__title>.icon{
    vertical-align: calc(-2rem/16);
    margin-right: calc(3rem/16);
}

/* box snowreport */

.box--snowreport .box__text{
    @media screen and (max-width: 767px){
       display: inline-block;
        margin-left: calc(5rem/16);
    }
}

/* box weather */

.box.box.box--weather{
    background-color: #fff;
    border-color: transparent;
    font-size: calc(15rem/16);
    padding: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(13rem/16);
    }
    @media screen and (min-width: 1300px){
        font-size: calc(17rem/16);
    }

    @media screen and (min-width: 1600px){
        font-size: calc(18rem/16);
    }
}

.box.box.box--weather.box--weather__inner{
    padding: 0;

    @media screen and (min-width: 768px){
        padding: calc(25rem/16) calc(20rem/16);
    }

    @media screen and (min-width: 1600px){
        padding: calc(38rem/16) calc(42rem/16);
    }
}

.box--weather__time{
    font-size: calc(20rem/16);
}
.box--weather__weather-img.big{
    height: calc(50rem/16);

    @media screen and (min-width: 768px){
        height: calc(50rem/16);
    }

    @media screen and (min-width: 1090px){
        height: calc(60rem/16);
    }

    @media screen and (min-width: 1200px){
        height: calc(75rem/16);
    }

    @media screen and (min-width: 1600px){
        height: calc(100rem/16);
    }
}

.box--weather__weather-img.small{
    height: calc(28rem/16);
}

.box--weather__value.big{
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
    }

    @media screen and (min-width: 1090px){
        font-size: calc(28rem/16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(35rem/16);
    }

    @media screen and (min-width: 1600px){
        font-size: calc(50rem/16);
    }

}

.box--weather__icon{
    color: #1DA0E4;
}

.box--weather__bottom{
    border-top: 1px solid #e5e5e5;
    padding: calc(16rem/16) calc(15rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(16rem/16) calc(20rem/16);
    }

    @media screen and (min-width: 1600px){
        padding: calc(21rem/16) calc(40rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1200px){
        font-size: calc(13rem/16);
    }

}

.box--weather .box__weather-img{
    @media screen and (min-width: 768px){
        margin: calc(12rem/16) 0;
    }

    @media screen and (min-width: 1200px){
        margin: calc(20rem/16) 0;
    }
}

/* box weather vertical */

.box--weather-vertical__title{
    font-size: calc(23rem/16);
    padding: 0 calc(10rem/16);

    @media screen and (min-width: 1400px){
        font-size: calc(28rem/16);
    }
}

.box--weather-vertical-img{
    height: calc(75rem/16);
}

.box--weather-vertical .box--weather__value.big{
    @media screen and (min-width: 1600px){
       font-size: calc(35rem/16);
    }
}

.box--weather-vertical__inner{
    padding: calc(20rem/16) calc(7rem/16);
    border-bottom: 1px solid #e2e2e2;

    @media screen and (min-width: 1400px){
        padding: calc(25rem/16) calc(40rem/16);
    }
    
    @media screen and (min-width: 1090px){
        padding: calc(20rem/16);
    }
}

.box--weather-vertical__inner--cols{
    padding: 0;
    border-bottom: 1px solid #e2e2e2;
}

.box--weather-vertical__bottom{
    padding: calc(24rem/16) calc(10rem/16) calc(28rem/16);
    
    @media screen and (min-width: 768px) and (max-width: 1090px){
       font-size: calc(14rem/16);
    }
}

.box__weather-item__time{
    @media screen and (min-width: 768px) and (max-width: 1400px){
        font-size: calc(15rem/16);
    }
}

.box__value{
    @media screen and (min-width: 768px) and (max-width: 1400px){
       font-size: calc(21rem/16);
    }
}

/*box weather new*/

.box--weather__info-text{
    font-size: calc(18rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }

    @media screen and (min-width: 1400px){
        font-size: calc(22rem/16);
    }
}

.box--weather__title{
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    text-align: center;

    @media screen and (min-width: 1400px){
        font-size: calc(40rem/16);
    }
}

.box--weather__date{
    font-size: calc(22rem/16);
    text-align: center;
    margin-bottom: calc(38rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(33rem/16);
    }

    @media screen and (min-width: 1400px){
        margin-bottom: calc(33rem/16);
        font-size: calc(28rem/16);
    }
}

.box--weather__right-item{
    display: flex;
    align-items: center;
    padding: calc(20rem/16) 0;
    justify-content: center;
    @media screen and (min-width: 768px){
        justify-content: flex-start;
        padding: 0;
    }
}

.box--weather__right-item+.box--weather__right-item{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.box--weather__inner--xs{
    @media screen and (max-width: 767px){
        padding: calc(22rem/16) calc(8rem/16);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}