.modal--warning .modal-header{
    border: none;
}

.warning-circle{
    width: calc(52rem/16);
    height: calc(52rem/16);
    border-radius: 50%;
    background-color: var(--color-danger);
    color: #fff;
    position: relative;
    font-family: var(--font-default-bold);
    font-size: calc(23rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: calc(20rem/16);
}

.warning-circle .icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/*-------------- darkpage ------------*/

.modal--warning.modal--warning-darkpage .modal-body {
    @media screen and (min-width: 768px) {
        padding: 1.25rem calc(81rem/16) calc(39rem/16) calc(81rem/16);
    }
}
.warning--darkpage-icon {
    height: calc(50rem/16);
    width: auto;

    @media screen and (min-width: 768px) {
        height: calc(80rem/16);
    }
    @media screen and (min-width: 1200px) {
        height: calc(108rem/16);
    }
}

.modal--warning .modal--warning-footer {
    padding: 0;
    border-top: none;
}
.modal--warning .modal--warning-footer .embed-responsive {
    border-radius: 0 0 1.875rem 1.875rem;
}