html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

.container-outer{
    overflow: hidden;
}
.container.container {
    max-width: calc(1055rem/16); /* 1025px breite + 30px padding */
    width: 100%;
}

.container-large{
    max-width: calc(1590rem/16); /* 1560px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-x-medium{
    max-width: calc(1270rem/16); /* 1300px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-medium{
    max-width: calc(1200rem/16); /* 1170px breite + 30px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}
.container-narrow {
    max-width: calc(650rem / 16);
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 768px)and (max-width: 1820px){
   .container, .container-medium, .container-large{
       padding-left: calc(120rem/16)!important; /* important because of pl-0, .. */
   }
}