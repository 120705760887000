.circle-icon{
   width: calc(42rem/16);
   height: calc(42rem/16);
    border-radius: 50%;
    border: 2px solid var(--color-primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    transition: all 150ms ease;

    @media screen and (min-width: 768px){
        width: calc(46rem/16);
        height: calc(46rem/16);
    }
}

.circle-icon--filled{
    background: linear-gradient(228.76deg, #D2A96C 0%, var(--color-primary) 100%);
    color: #fff;
    border: none;
}

.circle-icon.circle-icon--info{
    width: calc(25rem/16);
    height: calc(25rem/16);
    font-size: calc(13rem/16);
}

.circle-icon--info:hover{
    background-color: var(--color-primary);
    color: #fff;
}

.circle-icon--facilities{
    font-size: calc(24rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(27rem/16);
    }
}