/*.breadcrumb-container{*/
/*    margin-top: calc(40rem/16);*/
/*    @media screen and (min-width: 768px){*/
/*        margin-top: calc(80rem/16);*/
/*    }*/
/*}*/



@media screen and (max-width: 767px){
    .breadcrumb{
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        flex-wrap: nowrap;
    }
    .breadcrumb-item {
        float: none;
        display: inline-block;
    }
}

.breadcrumb-mobile, .breadcrumb-desktop {
    display: none;
}

@media screen and (max-width: 768px) {
    .breadcrumb-mobile {
        display: block;
    }
}
@media screen and (min-width: 768px) {
    .breadcrumb-desktop {
        display: block;
    }
}

.breadcrumb-has-transparent-bg ol.breadcrumb {
    background-color: transparent;
}

.breadcrumb-desktop.no-background ol.breadcrumb, .breadcrumb-mobile.no-background ol.breadcrumb {
    background-color: transparent;
}

@media screen and (max-width: 768px) {
    .breadcrumbs-outer-wrapper {
        z-index: 10;
        position: relative;
    }

    .breadcrumbs-outer-wrapper ol.breadcrumb {
        margin-bottom: -2rem;
        padding-bottom: 0;
    }
}



@media screen and (min-width: 768px) {
    .breadcrumbs-outer-wrapper {
        position: absolute;
        bottom: 0;
        margin: auto;
        width: 100%;
    }
}

.breadcrumbs-outer-wrapper {
    margin-top: 2rem;
}

.hero--content {
    padding-bottom: 0rem
}

@media screen and (min-width: 768px) {
    .hero--content {
        min-height: 100vh;
        padding-bottom: 3.125rem;
    }

    .hero--content .hero__content {
        margin-top: -23%;
    }
}


@media screen and (min-width: 1200px) {
    .hero--content .hero__content {
        margin-top: -15%;
    }
}

