.press-teaser {
    position: relative;
    font-size: calc(14rem / 16);
    line-height: calc(24 / 14);
    border-radius: calc(20rem / 16);
    box-shadow: 0 calc(30rem / 16) calc(30rem / 16) 0 rgba(0, 0, 0, .08);
    background-color: #fff;
    overflow: hidden;
    height: 100%;
    transition: box-shadow 300ms ease-in-out;
}

.press-teaser__body {
    padding: calc(25rem / 16) calc(30rem / 16);
}

.press-teaser__img {
    transform-origin: center;
    transition: transform 300ms ease-in-out;
}

.press-teaser:hover {
    box-shadow: 0 calc(30rem / 16) calc(30rem / 16) 0 rgba(0, 0, 0, .2);
}

.press-teaser:hover .press-teaser__img {
    border-top-left-radius: calc(20rem / 16);
    border-top-right-radius: calc(20rem / 16);
    transform: scale(1.05);
}

.press-teaser__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(20rem / 16);
    margin-bottom: calc(50rem / 16);
}