.slide-medium{
    padding-top: calc(25rem/16);
    padding-bottom: calc(35rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        padding-top: calc(80rem/16);
        padding-bottom: calc(80rem/16);
    }
}

.slide-medium.wysiwyg-area{
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(70rem/16);
        padding-bottom: calc(70rem/16);
    }
}

.slide-lg {
    padding-top: calc(30rem/16);
    padding-bottom: calc(40rem/16);
    position: relative;

}
@media screen and (min-width: 768px) {
    .slide-lg {
        padding-top: calc(50rem/16);
        padding-bottom: calc(100rem/16);
    }
}

.slide-bg{
    padding-top: calc(30rem/16);
    padding-bottom: calc(40rem/16);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px){
        padding-top: calc(70rem/16);
        padding-bottom: calc(150rem/16);
    }
}

.slide-bg-big{
    padding-top: calc(40rem/16);
    padding-bottom: calc(40rem/16);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px){
        padding-top: calc(135rem/16);
        padding-bottom: calc(135rem/16);
    }
}

.event-detail__slide-wrapper .slide-medium {
    padding-top: calc(25rem/16);
    padding-bottom: calc(35rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
}