.hide-dots .slick-dots{
    display: none!important;
}

.slider__arrow{
    background-color: transparent;
    border: none;
    line-height: 1;
    padding: calc(8rem/16);
    color: var(--color-primary);
}

.slick-arrow {
    height: calc(50rem / 16);
    width: calc(50rem / 16);
    border-radius: 50%;
    border: none;
    background: linear-gradient(270deg,#d2a96d 45%,var(--color-primary) 85%);
    background-size: 150% auto;
    color: var(--color-white);
    outline: none !important;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.slick-arrow:hover {
    background-position: 30%;
    box-shadow: 0 0.625rem 0.625rem 0 rgb(0 0 0 / 10%);
}

.slick-disabled.slick-arrow {
    opacity: 0.5;
    pointer-events: none;
}

.slider__arrow__icon {
    font-size: calc(14rem / 16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slick-prev {
    position: absolute;
    bottom: 0;
    left: calc(15rem / 16);
}

.slick-next {
    margin-top: calc(30rem / 16);
    margin-left: calc(70rem / 16);
}

.content-teaser-slider .slick-slide,
.img-teaser-slider .slick-slide {
    transition: all 0.2s ease-in-out;
}

.content-teaser-slider .slick-slide.slick-active,
.img-teaser-slider .slick-slide.slick-active {
    opacity: 1;
}

.content-teaser-slider .slick-slide:not(.slick-active),
.img-teaser-slider .slick-slide:not(.slick-active) {
    opacity: 0.3;
}