.wysiwyg-with-medium__media{
    box-shadow: 0 30px 30px 0 rgba(0,0,0,0.08);
}

.wysiwyg-with-medium__media .vjs-poster{
    background-size: cover;
}

.wysiwyg-with-medium__app-links a+a{
    margin-left: calc(20rem/16);

    @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: calc(8rem/16);
    }
}

.wysiwyg-with-medium__media,
.wysiwyg-with-medium__media img{
    border-radius: calc(20rem/16);
}