.main-nav__sub-nav{
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
    padding: calc(48rem/16) calc(40rem/16) calc(40rem/16);
    transform: translateX(100%);
    transition: all 250ms ease;
    width: 100%;

    @media screen and (min-width: 768px){
        transform: translateX(-115%);
        padding: calc(166rem/16) 0 calc(30rem/16) calc(175rem/16);
        z-index: -1;
        width: calc(620rem/16);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media screen and (min-width: 1500px) {
        padding-bottom: calc(50rem/16);
    }
}

.main-nav__nav-item.is-open .main-nav__sub-nav{
    transform: translateX(0);
}

.main-nav__sub-nav>ul>li {
    position: relative;
    @media screen and (min-width: 768px){
        z-index: 10;
    }
}

.main-nav__sub-nav>ul>li:after {
    content: '';
    position: absolute;
    width: calc(75rem/16);
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    transform: translateX(95%);
    display: none;
}

.main-nav__sub-nav>ul>li.is-open:after {
    display: block;
}

.main-nav__sub-nav:after {
    @media screen and (min-width: 768px){
        transform: skewX(-5deg);
        content: '';
        position: absolute;
        right: calc(-50rem/16);
        bottom: 0;
        top: 0;
        background-color: #fff;
        width: calc(100rem/16);
    }
}

.main-nav__sub-nav__title{
    font-size: calc(28rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: calc(1.33rem/16);
    line-height: calc(50/40);
    margin-bottom: calc(30rem/16);
    
    @media screen and (min-width: 768px){
       font-size: calc(34rem/16);
       max-width: 93%;
    }

    @media screen and (min-width: 1500px){
       font-size: calc(40rem/16);
        margin-bottom: calc(50rem/16);
    }
}

.navbar-nav .main-nav__sub-nav ul>li>.nav-link{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: calc(29/25);
    letter-spacing: calc(0.83rem/16);
    padding: calc(10rem/16) 0;

    @media screen and (min-width: 1500px){
        font-size: calc(25rem/16);
        padding: calc(18rem/16) 0;
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(10rem/16);
    }
}

.navbar-nav .main-nav__sub-nav ul.menu-hover>li>.nav-link{
    opacity: 0.3;
}

.navbar-nav .main-nav__sub-nav ul.menu-hover>li.is-open>.nav-link{
    opacity: 1;
}

.main-nav__sub-nav__list{
    position: relative;
}
.main-nav__sub-nav__list:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 75px;
    z-index: 10;
    transform: translateX(100%);
}

.main-nav__sub-nav__sub{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
    display: block;
    padding: calc(50rem/16);
    padding-left: calc(45rem/16);
    transform: translateX(100%);
    transition: all .2s ease-in-out;
    width: 100%;

    @media screen and (min-width: 768px){
        transform: translateX(109%) skew(-5deg);
        transition: opacity .2s ease-in-out;
        visibility: hidden;
        opacity: 0;
        width: calc(500rem/16);
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
    }
}

.main-nav__sub-nav__sub>ul{
    @media screen and (min-width: 768px){
        transform: skew(5deg);
    }
}

.navbar-nav .main-nav__sub-nav .main-nav__sub-nav__sub ul>li>.nav-link{
    font-size: calc(20rem/16);
}

.main-nav__sub-nav ul.menu-hover>li.is-open .main-nav__sub-nav__sub{
    visibility: visible;
    opacity: 1;
}

.navbar-nav .main-nav__sub-nav__sub ul>li>.nav-link{
    font-size: calc(20rem/16);
    font-family: var(--font-default);
}

@media screen and (max-width: 767px){
    .main-nav__sub-nav>ul>li.is-open .main-nav__sub-nav__sub{
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
    }
}

