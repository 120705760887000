.percent-circle {
    display: block;
    position: relative;
    width: calc(54rem / 16);
    height: calc(54rem / 16);

    @media screen and (min-width: 768px){
        width: calc(46rem / 16);
        height: calc(46rem / 16);
    }
}
.percent-circle-container {
/*    max-width: calc(90rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(72rem / 16);
    }

    @media screen and (min-width: 1500px) {
        max-width: calc(80rem / 16);
    }
    */
    @media screen and (max-width: 767px){
       margin: 0 auto;
    }
}
.percent-circle svg {
    width: 100%;
    height: 100%;
    position: relative;
}
.percent-circle__circle {
    stroke: #fff;
    stroke-width: calc(4rem / 16);
}
.percent-circle__circle--percent {
    stroke-width: calc(4rem / 16);
    stroke: var(--color-primary);
    transition: stroke-dasharray 600ms ease-in-out;
}
.percent-circle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px){
        font-size: calc(17rem / 16);
    }
}
.percent-circle__icon.icon-elevator {
    margin-left: calc(2rem / 16);
}