.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select__input-wrapper:before {
    font-family: "iconfont";
    content: var(--icon-dropdown);
    color: var(--color-primary);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(16rem / 16);
    top: 50%;
    right: calc(15rem / 16);
    transform: translateY(-50%);
    z-index: 1;

    @media screen and (min-width: 768px) {
        right: calc(22rem / 16);
    }
}
select.form-control:not([size]):not([multiple]) {
    height: calc(48rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(50rem / 16);
    }
}
select.form-control option {
    color: var(--color-dark);
}