.hero__switch{
    background: linear-gradient(227.99deg, #D2A96C 0%, #D2A96C 30.2%, var(--color-primary) 100%);
    border-radius: calc(1000rem/16);
    padding: calc(3rem/16);
    display: inline-block;
    pointer-events: auto;

    @media screen and (min-width: 768px){
        padding: calc(4rem/16);
    }

    @media screen and (max-width: 767px){
        margin-bottom: calc(35rem/16);
    }
}

.hero__switch .nav{
    @media screen and (max-width: 767px){
        justify-content: center;
    }
}

.hero__switch__btn{
    font-size: calc(11rem/16);
    letter-spacing: calc(0.5rem/16);
    background-color: transparent;
    color: #ffffff;
    font-family: var(--font-default-bold);
    padding: calc(6rem/16) calc(10rem/16);
    border-radius: calc(1000rem/16);
    display: inline-block;
    text-transform: uppercase;
    position: relative;

    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
        padding: calc(12rem/16) calc(18rem/16);
        letter-spacing: calc(2.5rem/16);
    }

    @media screen and (min-width: 1400px){
        font-size: calc(20rem/16);
        letter-spacing: calc(4rem/16);
        padding: calc(15rem/16) calc(40rem/16);
    }

    @media screen and (min-width: 1500px){
        font-size: calc(20rem/16);
        letter-spacing: calc(5rem/16);
    }
}

.hero--portal .hero__switch__btn,
.hero--simple .hero__switch__btn{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
        letter-spacing: calc(2.5rem/16);
        padding: calc(5rem/16) calc(15rem/16);
    }
}

.hero__switch__btn:after{
    content: '';
    position: absolute;
    bottom: calc(4rem/16);
    left: calc(9rem/16);
    right: calc(18rem/16);
    height: calc(3rem/16);
    background-color: #fff;
    opacity: 0;
    transition: all 350ms ease;
    transform: scaleX(0);
    transform-origin: right;

    @media screen and (min-width: 1280px){
        left: calc(40rem/16);
        right: calc(28rem/16);
        bottom: calc(10rem/16);
    }
}

.hero__switch__btn + .hero__switch__btn:after{
    left: calc(18rem/16);
    right: calc(9rem/16);
    transform-origin: left;

    @media screen and (min-width: 1280px){
        left: calc(40rem/16);
        right: calc(28rem/16);
    }

}

.hero__switch__btn.active{
    background-color: #fff;
    color: var(--color-primary);
}

.hero__switch__btn:not(.active):hover{
    color: #fff;
}

.hero__switch__btn:hover:after{
    opacity: 1;
    transform: scaleX(1);
}

/*
.hero__switch__btn:before{
    content: '';
    position: absolute;
    right: calc(17rem/16);
    bottom: calc(6rem/16);
    width: 0;
    height: 0;
    border-top: calc(6rem/16) solid transparent;
    border-bottom: calc(6rem/16) solid transparent;

    border-left: calc(12rem/16) solid #fff;

    opacity: 0;
    transition: all 80ms ease;

}

.hero__switch__btn:hover:before{
    opacity: 1;
    transition-delay: 300ms;


}*/
