.isgold--awards {
    background: linear-gradient(228.76deg, #D2A96C 0%, var(--color-primary) 100%);
    position: relative;
    /*box-shadow: inset 0 40px 60px 0 rgba(0,0,0,0.2);*/
}

.isgold--awards:after {
    content: '';
    position: absolute;
    height: 100px;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%,rgba(69,72,77,0.04) 78%,rgba(69,72,77,0) 99%,rgba(69,72,77,0) 100%);
}

.isgold--awards .awards-box__title, .isgold--awards .awards-box__text {
    color: white;
}

.awards-box {
    position: relative;
}

.iswhite--awards:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%,rgba(69,72,77,0.04) 78%,rgba(69,72,77,0) 99%,rgba(69,72,77,0) 100%);
    content: '';
}
.awards-box:first-of-type .iswhite--awards:after {
    background: transparent;
}

.awards-box__title {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(28rem/16);

    @media screen and (min-width: 786px) {
        font-size: calc(45rem/16);
    }

}
.awards-box__text {
    font-family: var(--font-default-light);

}
.awards-box__picture {
    align-self: center;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}