.quote-area{
    position: relative;
}

.quote-area::before{
    content: '';
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-primary-light);
}

.quote-area__content{
    position: relative;
}

.quote-area__author-img{
    position: absolute;
    bottom: auto;
    top: calc(-140rem/16);
    left: 50%;
    transform: translateX(-50%);
    width: calc(120rem/16);
    border-radius: 50%;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.08);
    border: 3px solid #FFF;
    filter: grayscale(100%);

    @media screen and (min-width: 768px){
        bottom: calc(55rem/16);
        top: auto;
        left: auto;
        transform: translateX(-117%);
        width: calc(225rem/16);
    }
}

.quote-area__author{
    text-align: center;
    line-height: calc(26rem/16);
    margin: calc(80rem/16) 0 0;
    padding-bottom: calc(30rem/16);
    position: relative;
    @media screen and (min-width: 768px){
        text-align: left;
        line-height: calc(30rem/16);
        margin: calc(40rem/16) 0 0;
        padding-bottom: calc(88rem/16);
        padding-left: calc(133rem/16);
    }
}

.quote-area__author-name{
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
    }
}

.quote-area__author-description{
    font-size: calc(16rem/16);
    margin-top: calc(4rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        margin-top: calc(6rem/16);
    }
}

.quote-area__icon{
    position: absolute;
    top: calc(-16rem/16);
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    font-size: calc(100rem/16);
    color: #F9F6F2;

    @media screen and (min-width: 768px){
        transform: none;
        top: calc(-25rem/16);
        left: calc(-5rem/16);
    }
}

.quote-area__quote{
    position: relative;
    z-index: 1;
    text-align: center;
    font-style: italic;
}

.quote-area__quote-box{
    padding: calc(25rem/16) calc(16rem/16) calc(80rem/16);
    font-size: calc(18rem/16);
    line-height: calc(30rem/16);
    background-color: #fff;
    border-color: transparent;
    box-shadow: 0 30px 30px 0 rgba(0,0,0,.08);
    border-radius: 1.25rem;

    @media screen and (min-width: 768px){
        line-height: calc(38rem/16);
        padding: calc(100rem/16) calc(135rem/16) calc(80rem/16);
        font-size: calc(22rem/16);
    }
}