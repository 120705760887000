.recommended-bottom-area{
    position: relative;
}

.recommended-bottom-area:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 68%;
    background: #EAE0D2;
    z-index: -1;
}

.recommended-bottom-area .content-teaser__text{
    color: rgba(0, 0, 0, 0.5);
}

.recommended-bottom-area .content-img-teaser-slider.slick-dotted {
    display: flex!important;
    flex-direction: column;
    align-items: center;
}

.recommended-bottom-area .content-img-teaser-slider.slick-dotted button {
    left: unset;
    margin-left: 0;
}

.recommended-bottom-area .content-img-teaser-slider.slick-dotted button {
    transform: translateX(35px);
}

.recommended-bottom-area .content-img-teaser-slider.slick-dotted button:first-child {
    transform: translateX(-35px);
}
