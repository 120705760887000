/*.state-circle {
    width: calc(10rem / 16);
    height: calc(10rem / 16);
    border-radius: 50%;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.3);
    border: calc(1rem / 16) solid #fff;
    background: var(--color-success);
    margin-right: calc(3rem / 16);

    @media screen and (min-width: 1200px) {
        margin-right: calc(6rem / 16);
        width: calc(14rem / 16);
        height: calc(14rem / 16);
        border-width: calc(2rem / 16);
    }
}*/

.state-circle {
    position: relative;
    padding-left: calc(12rem/16);

    @media screen and (min-width: 768px) {
        padding-left: calc(12rem/16);
    }
    @media screen and (min-width: 1200px) {
        padding-left: calc(15rem/16);
    }
}
.state-circle:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;

    width: calc(11rem / 16);
    height: calc(11rem / 16);
    border-radius: 50%;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.3);
    border: calc(1rem / 16) solid #fff;
    background: var(--color-success);
    margin-right: calc(3rem / 16);

    @media screen and (min-width: 1200px) {
        margin-right: calc(6rem / 16);
        width: calc(14rem / 16);
        height: calc(14rem / 16);
        border-width: calc(2rem / 16);
    }

    @media screen and (max-height: 729px) and (min-width: 768px) {
        width: calc(10rem / 16);
        height: calc(10rem / 16);
        border: calc(1rem / 16) solid #fff;
    }
}
.state-circle.state-danger:before {
    background: var(--color-danger);
}
.state-circle.state-success:before {
    background: var(--color-success);
}
.state-circle__parking {
    position: relative;
    width: calc(30rem/16);
    height: calc(30rem/16);
    padding-left: calc(12rem/16);

    @media screen and (min-width: 768px) {
        padding-left: calc(12rem/16);
        width: clamp(calc(22rem / 16), 2vw, calc(30rem / 16));
        height: clamp(calc(22rem / 16), 2vw, calc(30rem / 16));
    }
    @media screen and (min-width: 1200px) {
        padding-left: calc(15rem/16);
    }
}
.state-circle__parking:before {
    content: 'P';
    transform: translateX(-50%);
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.3);
    border: calc(2rem / 16) solid #fff;
    background: var(--color-success);
    margin-right: calc(3rem / 16);
    font-size: calc(16rem/16);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;


	@media screen and (min-width: 768px){
        width: clamp(calc(22rem / 16), 2vw, calc(30rem / 16));
        height: clamp(calc(22rem / 16), 2vw, calc(30rem / 16));
	}

    @media screen and (min-width: 1200px) {
        margin-right: calc(6rem / 16);

        border-width: calc(2rem / 16);
    }

    /*@media screen and (max-height: 729px) and (min-width: 768px) {
        width: calc(20rem / 16);
        height: calc(20rem / 16);
        border: calc(1rem / 16) solid #fff;
    }*/
}
.state-circle__parking.state-danger:before {
    background: var(--color-danger);
}
.state-circle__parking.state-success:before {
    background: var(--color-success);
}