.img-text-slide{
    position: relative;
}

.img-text-slide__body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
}

.img-text-slide__title{
    font-family: var(--font-default-bold);
}

.img-text-slide__text{
    font-size: calc(16rem/16);
    line-height: calc(25/18);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
        line-height: calc(32/22);
    }
}


/*------------overlay-------------*/
.img-text-slide__overlay{
    position: absolute;
    width: 100%;
    background-color: rgba(255,255,255,0.55);
    height: auto;
    top: 0;
    bottom: 0;
}
.img-text-slide__darkImg {
    color: white;
}
.img-text-slide__darkImg .img-text-slide__overlay {
    background-color: rgba(0, 0, 0, 0.35);
}