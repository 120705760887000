html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default-light);
    font-size: calc(16rem/16);
    line-height: calc(30/16);

    @media screen and (min-width: 768px){
        font-size: var(--font-size-default);
        line-height: calc(32/18);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: normal;
}

.font-light {
    font-family: var(--font-default-light);
    font-weight: normal;
}

b,
strong,
strong,
.font-bold {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(35rem/16);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: responsive 45px 70px; /* min-size, max-size */
        font-range: 992px 1920px; /* viewport widths between which font-size is fluid */
    }
}
h2, .h2 {
    font-size: calc(30rem/16);
    @media screen and (min-width: 768px){
        font-size: responsive 38px 45px; /* min-size, max-size */
        font-range: 992px 1920px; /* viewport widths between which font-size is fluid */
    }
}
h3, .h3 {
    font-size: calc(22rem/16);

    @media screen and (min-width: 768px){
       font-size: 25px;
    }
}
h4, .h4 {
    font-size: calc(20rem/16);
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}

.coi-banner__headline {
    font-family: var(--font-default-light);
}