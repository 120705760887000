.hero__box-container {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    position: relative;
    z-index: 2;
    font-family: var(--font-default);

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    /*
    @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-top: 10vh;
    }*/
    @media screen and (min-width: 768px) {
        margin-top: 20vh;
    }
    @media screen and (min-width: 1500px) {
        margin-top: 26vh;
    }
}
.hero__box-container:after {
    opacity: 0.8;
    content: '';
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -1;
    position: absolute;
}
.hero__box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;

    @media screen and (min-width: 768px) {
        max-width: 25%;
        min-height: calc(150rem / 16);
    }

    @media screen and (min-width: 1600px) {
        min-height: calc(226rem / 16);
    }

    @media screen and (max-height:720px) and (min-width: 768px) {
        min-height: calc(150rem/16);
    }



}
.hero__box + .hero__box {
}
.hero__box__bg {
    transform: skew(-15deg);
    position: absolute;
    top: 0;
    left: 0;
    right: calc(-35rem / 16);
    bottom: 0;

        @media screen and (min-width: 768px) {
            right: 0;
            background: linear-gradient(209.17deg, rgba(255,255,255,0) 0%, #fff 100%);
        }
}

.hero__box:first-child .hero__box__bg{
    @media screen and (min-width: 768px){
       left: calc(-32rem/16);
    }
}

.hero__box--facilities .hero__box__bg{
    @media screen and (min-width: 768px){
       right: calc(-200rem/16);
    }
}

.hero__box__bg-img {
    @media screen and (min-width: 768px) {
        right: calc(30rem/16);
        width:100%;
        height: auto;
    }
}
.hero__box__bg-img.hero__box__bg-img--parking {
    @media screen and (min-width: 768px) {
        transform: skew(0);
    }
}
/*
.hero__box__bg:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(209.17deg, rgba(255,255,255,0) 0%, #fff 100%);
}
*/

.hero__box__bg:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background:  linear-gradient(270deg, rgba(64,64,64,0) 0%, #40404030 100%);
}
.hero__box__bg:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.hero__box:nth-child(6) .hero__box__bg:before, .hero__box:nth-child(1) .hero__box__bg:before {
    //background: transparent;
}

.hero__box__bg:first-of-type:after, .hero__box__bg:last-of-type:after {

}

.hero__box--shrink {
    flex-grow: 0;
}
.hero__box--bg {
    min-width: calc(190rem / 16);
    text-shadow: 0 2px 4px black;
    color: #fff;

    @media screen and (max-width: 767px) {
        min-height: calc(200rem / 16);
        margin-left: calc(10rem/16);
    }
}
.hero__box--bg .hero__box__bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.hero__box--bg {
    @media screen and (min-width: 768px) {
        width: calc(240rem / 16);
    }
}
.hero__box--md {
    min-width: 19%;
}
.hero__box:first-child {
    @media screen and (min-width: 768px) {
        margin-left: calc(-30rem / 16);
        padding-left: calc(30rem / 16);
    }
}
/*.hero__box:last-child {
    @media screen and (min-width: 768px) {
        margin-right: calc(-30rem / 16);
        padding-right: calc(30rem / 16);
    }
}*/
.hero__box--bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.hero__box + .hero__box .hero__box__top {
    @media screen and (min-width: 768px) {
        position: relative;
        left: -2%;
    }
    /*
    @media screen and (max-height: 729px) and (min-width: 768px) {
        max-height: calc(35rem/16);
    }*/
}
.hero__box__top {
    position: relative;
    top: 0;
    z-index: 2;
    margin-top: calc(-15rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: calc(-5rem / 16);
    }
    @media screen and (min-width: 1500px) {
        margin-bottom: calc(-10rem / 16);
    }
}
.hero__box__top__title {
    font-size: calc(18rem / 16);
    flex-grow: 1;
    margin-left: calc(7rem/16);
    /*text-align: center;*/

    @media screen and (min-width: 768px) {
        text-align: left;
        font-size: calc(10rem / 16);
        margin-left: calc(3rem / 16);
    }
    @media screen and (min-width: 1075px){
        font-size: calc(11rem / 16);
    }
    @media screen and (min-width: 1120px){
        font-size: calc(12rem / 16);
    }
    @media screen and (min-width: 768px){
        padding-right: calc(20rem/16);
    }
    @media screen and (min-width: 1350px) {
        font-size: calc(16rem / 16);
    }
    @media screen and (min-width: 1500px){
        font-size: calc(17rem / 16);
        margin-left: calc(10rem / 16);
    }
    @media screen and (min-width: 1800px) {
        font-size: calc(20rem / 16);
    }

}
.hero__box--facilities .hero__box__top__title {
    @media screen and (min-width: 1700px){
       padding-right: 0;
    }
}

.hero__box__icon {
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    border-radius: 50%;
    background: rgba(255,255,255,.4);
    position: relative;
    text-shadow: none;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        width: calc(35rem / 16);
        height: calc(35rem / 16);
    }
    @media screen and (min-width: 1075px){
        width: calc(42rem / 16);
        height: calc(42rem / 16);
    }
   /* @media screen and (min-width: 1200px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
    }*/
    @media screen and (min-width: 1500px) {
        width: calc(70rem / 16);
        height: calc(70rem / 16);
    }
    @media screen and (min-width: 1760px){
        width: calc(98rem / 16);
        height: calc(98rem / 16);
    }
    @media screen and (max-width: 767px){
       margin-left: calc(12rem/16);
    }


    /*@media screen and (max-height: 729px) and (min-width: 768px) and (max-width: 1500px){
        width: calc(60rem/16);
        height: calc(60rem/16);
    }
    @media screen and (max-height: 729px) and (min-width: 1501px) {
        width: calc(75rem/16);
        height: calc(75rem/16);
    }*/
}
.hero__box__icon:before {
    content: '';
    position: absolute;
    top: calc(8rem / 16);
    right: calc(8rem / 16);
    bottom: calc(8rem / 16);
    left: calc(8rem / 16);
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.12);

    
    @media screen and (min-width: 768px){
        top: calc(2rem / 16);
        right: calc(2rem / 16);
        bottom: calc(2rem / 16);
        left: calc(2rem / 16);

    }

    @media screen and (min-width: 1200px){
        top: calc(5rem / 16);
        right: calc(5rem / 16);
        bottom: calc(5rem / 16);
        left: calc(5rem / 16);
    }

    @media screen and (min-width: 1760px){
        top: calc(15rem / 16);
        right: calc(15rem / 16);
        bottom: calc(15rem / 16);
        left: calc(15rem / 16);
    }

}


/*.hero__box__icon--map{
    position: absolute;
 }*/

.hero__box__icon__icon {
    font-size: calc(25rem / 16);
    color: #6D7278;
    transition: color 200ms ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 768px) {
        font-size: calc(17rem / 16);
    }
    @media screen and (min-width: 1075px){
        font-size: calc(20rem / 16);
    }
    @media screen and (min-width: 1500px){
        font-size: calc(28rem / 16);
    }
    @media screen and (min-width: 1760px) {
        font-size: calc(35rem / 16);
    }

    @media screen and (max-height: 729px) and (min-width: 768px){
        font-size: calc(25rem/16);
    }

}

/*.hero__box__icon__icon.icon-snow{
    @media screen and (max-width: 767px){
       font-size: calc(35rem/16);
    }
}

.hero__box__icon__icon.icon-weather{
    @media screen and (max-width: 767px){
        font-size: calc(40rem/16);
    }
}*/

/*
.hero__box__icon__icon.icon-ski{
    @media screen and (max-width: 767px){
        font-size: calc(33rem/16);
    }
}
*/

.hero__box:hover .hero__box__icon__icon {
    color: var(--color-primary);
}
.hero__box__icon__icon.icon-parking {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(17rem / 16);
    }

    @media screen and (min-width: 1500px){
        font-size: calc(22rem / 16);
    }

    @media screen and (min-width: 1760px){
        font-size: calc(26rem / 16);
    }

    @media screen and (max-height: 729px) and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

.hero__box__content {
    position: relative;
    z-index: 0;
    margin: auto 0;
    padding: 0 calc(10rem / 16) calc(30rem / 16);
    font-size: calc(16rem / 16);
    line-height: calc(18/16);

    @media screen and (max-width: 767px) {
        flex-grow: 1;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    @media screen and (min-width: 768px) {
        margin-top: auto;
        font-size: calc(10rem / 16);
        padding: 0 calc(20rem / 16) calc(15rem / 16) 0;
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(12rem / 16);
        padding: 0 calc(25rem / 16) calc(15rem / 16) 0;
    }
    @media screen and (min-width: 1650px) {
        padding: 0 calc(40rem / 16) calc(30rem / 16) calc(10rem / 16);
        font-size: calc(13rem / 16);
    }
    @media screen and (min-width: 1700px) {
        font-size: calc(16rem/16);
    }
}

.hero__box__content.hero__box__content--parking {
    display: flex;
    align-items: center;
    max-width: none;
    margin-top: calc(25rem/16);
    margin-left: calc(10rem/16);
    @media screen and (max-width: 767px){
        margin-left: calc(50rem/16);
        margin-top: calc(0rem/16);
    }

}

.hero__box--fit-content{
    max-width: fit-content;
}


.hero__box-parking-margin{
    @media screen and (min-width: 768px) {
        margin-left: calc(15rem/16);
    }

    @media screen and (min-width: 1075px){
        margin-left: calc(22rem/16);
    }

    @media screen and (min-width: 1500px) {
        margin-left: calc(50rem/16);
    }
    @media screen and (min-width: 1760px){
        margin-left: calc(78rem/16);
    }
}

.hero__box__content.hero__box__content--parking.hero__box__content--parking-region{
    max-width: 15%;
}

.hero__box .weather-item{
    @media screen and (min-width: 768px){
       font-size: calc(13rem/16);
    }
    @media screen and (min-width: 1700px){
       font-size: calc(16rem/16);
    }
}

.hero__box__content__col {
    width: 50%;
    min-width: calc(70rem / 16);
    padding: 0 calc(4rem / 16);

    @media screen and (min-width: 768px) {
        width: 33%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px){
        padding: 0 calc(2rem / 16);
        min-width: calc(55rem / 16);
    }

    @media screen and (min-width: 1200px) {
        width: 25%;
    }
    
    @media screen and (max-width: 767px){
       min-height: calc(90rem/16);
    }
}
.hero__box__content__col--full-md {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 100%;
    }
}
.hero__box__content.hero__box__content--absolute {
    position: absolute;
    padding: 0;
    left: calc(40rem / 16);
    right: calc(8rem / 16);
    top: calc(40rem/16);

    /*----------------------------*/
    @media screen and (min-width: 768px) {
        bottom: calc(10rem/16);
    }

    @media screen and (min-width: 1200px) {
        top: calc(50rem/16);
    }

    @media screen and (min-width: 1500px) {
        left: calc(50rem / 16);
        top: calc(80rem/16);
    }

    /*----------------------------*/
    @media screen and (min-width: 1649px) {
        top: calc(110rem/16);
    }
}

.hero-boxes__parking-text{
    width: fit-content;
    height: calc(25rem/16);
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba(219,219,219,0) 15%, rgba(241,241,241,0.7) 25%, rgba(255,255,255,1) 100%);
    padding: calc(0rem/16) calc(10rem/16);
    border-radius: calc(15rem/16);
    color: var(--color-text-default);
    font-size: clamp(calc(10rem/16), 1vw, calc(16rem/16));

    @media screen and (max-width: 767px){
        font-size: clamp(calc(10rem/16), 3vw, calc(16rem/16));
    }
}


.hero__box__content__value {
    font-size: calc(40rem / 16);
    line-height: 0.8;

    @media screen and (min-width: 768px) {
        font-size: calc(35rem / 16);
    }
    @media screen and (min-width: 1500px){
        font-size: calc(50rem / 16);

    }
    @media screen and (min-width: 1700px) {
        font-size: calc(60rem / 16);
    }

    @media screen and (max-height: 729px) and (min-width: 768px) and (max-width: 1599px) {
        font-size: calc(26rem/16);
    }
}

.hero__box__content__value-sm {
    font-size: calc(22rem / 16);
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        margin-top: calc(4rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(16rem / 16);
    }
    @media screen and (min-width: 768px){
        font-size: calc(18rem / 16);
    }
    @media screen and (min-width: 1700px) {
        font-size: calc(22rem / 16);
        margin-top: calc(10rem / 16);
    }

   /* @media screen and (max-height: 729px) and (min-width: 768px) {
        font-size: calc(12rem/16);
    }*/
}

/*-------------------*/
.hero__box__content__list-segment {
    margin-top: calc(10rem/16);
    @media (min-width: 1500px) {
        margin-top: calc(10rem/16);
    }
}
.hero__box__content__list-segment+.hero__box__content__list-segment{
    margin-top: calc(15rem/16);
}

.hero__box__content__list-segment.is-multi-column{
    column-count: 2;

}
.hero__box__content__list-circle {
    /*width: calc(14rem/16)!important;
    height: calc(14rem/16)!important;*/
    @media screen and (min-width: 768px) and (max-width:1649px) {
        width: calc(10rem/16)!important;
        height: calc(10rem/16)!important;
        margin-bottom: 0;
    }
    @media screen and (min-width: 1650px) {
        margin-bottom: calc(-2rem/16);
    }
}

.hero__box__content__list li + li {
    margin-top: calc(2rem / 16);
}

.hero__box__content__list-segment .state-circle:after{
    position: absolute;
    content: 'P';
    color: #fff;
    top: calc(3rem/16);
    font-size: calc(6rem/16);
    left: calc(4rem/16);

    @media screen and (min-width: 1200px){
        top: calc(3rem/16);
        font-size: calc(8rem/16);
        left: calc(3rem/16);
    }

    @media screen and (min-width: 1500px){
        left: calc(5rem/16);
        top: calc(3rem/16);
    }
}

.hero__box__content__list .state-circle:before{
    top: calc(6rem/16);

    width: calc(12rem/16);
    height: calc(12rem/16);

    @media screen and (min-width: 1200px){
        top: calc(7rem/16);
    }

    @media screen and (min-width: 1500px){
        width: calc(15rem/16);
        height: calc(15rem/16);
    }
}

/*-------------------*/
.hero__box__content__list-segment li, .hero__box__content__list-segment li + li {
    margin-top: calc(4rem/16);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
       font-size: calc(11rem/16);
    }
    @media screen and (min-width: 1200px){
        font-size: calc(13rem/16);
    }
    @media screen and (min-width: 1700px) {
        font-size: calc(16rem/16);
    }

    @media screen and (max-height: 729px) and (min-width: 768px) {
        font-size: calc(12rem/16)!important;
        margin-top: calc(2rem/16)!important;
    }
}

.hero__box__content__icon {
    font-size: calc(26rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(22rem / 16);
    }
    @media screen and (min-width: 1500px) {
        font-size: calc(26rem / 16);
    }

    @media screen and (max-height:799px) and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.hero__content__avalanche-img {
    height: calc(48rem/16);
    width: auto;
    margin-bottom: calc(4rem / 16);
    aspect-ratio: 42 / 35;

    @media screen and (min-width: 1200px) {
        margin-bottom: calc(16rem / 16);
        font-size: calc(40rem / 16);
    }
    @media screen and (min-width: 768px){
        height: calc(35rem/16);
    }

    @media screen and (max-height: 729px) and (min-width: 768px){
        height: calc(28rem/16);
    }
}

/* mobile order boxes */
@media screen and (max-width: 767px) {
    .hero__box--facilities {
        order: 0;
    }
    .hero__box--map {
        order: 2;
        margin: 0;
    }
    .hero__box--snowreport {
        order: 3;
    }
    .hero__box--weather {
        order: 4;
    }
    .hero__box--webcams {
        order: 5;
    }
    .hero__box--parking {
        order: 6;
    }
}

.hero__box-container--images {
    @media screen and (min-width: 768px) {
        margin-left: calc(-30rem / 16);
        margin-right: calc(-30rem / 16);
    }
}
.hero__box-image {
    animation: opacity 250ms 1 ease-in-out forwards;
    height: calc(226rem / 16);
    width: 100%;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        transform: skew(-15deg);
        height: calc(150rem / 16);
    }

    @media screen and (min-width: 1500px) {
        height: calc(226rem / 16);
    }
}
.hero__box-image:first-child {
    @media screen and (min-width: 768px) {
        padding-left: calc(12rem / 16);
    }
}
.hero__box-image__bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media screen and (min-width: 768px) {
        transform: skew(15deg);
        left: calc(-30rem / 16);
        right: calc(-30rem / 16);
    }
}
.hero__box-image__bg:after {
    content: '';
    opacity: .9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(317.68deg, rgba(0,0,0,0) 0.02%, #000000 100%);
    transition: opacity 200ms ease-in-out;
}
.hero__box-image:hover .hero__box-image__bg:after {
    opacity: .7;
}
.hero__box-image__content {
    padding: calc(30rem / 16);
    position: relative;
    z-index: 1;
    font-size: calc(18rem / 16);
    line-height: calc(24/20);
    height: 100%;

    @media screen and (min-width: 768px) {
        transform: skew(15deg);
    }

    @media screen and (min-width: 1500px) {
        font-size: calc(20rem / 16);
        padding: calc(30rem / 16) calc(45rem / 16);
    }
}
.hero__box-image__title {
    font-size: calc(30rem / 16);
    line-height: calc(46 / 40);

    @media screen and (min-width: 1500px) {
        font-size: calc(40rem / 16);
    }
}

@keyframes opacity {
    0% { opacity: 0;}
    100% { opacity: 1;}
}


.hero__box__link-area {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
}

.hero__box--parking{
    @media screen and (min-width: 768px) and (max-width: 1100px){
        max-width: calc(235rem/16);
    }
}

.hero__box--facilities {
    position: relative;
    z-index: 1;

}

.hero__box--map {
    position: relative;
    z-index: 0;
}

.hero__box__iframe{
    margin: 0;
    object-fit: cover;
    width: calc(100% + 35px);
    position: relative;
    left: calc(-35rem/16);
}