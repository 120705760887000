.horizontal-teaser__title {
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
    }
}
.horizontal-teaser__text {
    font-family: var(--font-default-light);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.horizontal-teaser__body__bottom {
    font-family: var(--font-default-light);
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }

}